import AppRoutes from "./routing/Routes";
import axios from "axios";
import ahoy from "ahoy.js";
import { BrowserRouter } from "react-router-dom";
import setupAxios from "./util/configs";

const { REACT_APP_API_URL }: any = process.env;

export const SAVED_PRODUCTS_KEY = "AA_CART_PRODUCTS";

ahoy.configure({
  urlPrefix: REACT_APP_API_URL,
  withCredentials: true,
});

function App() {
  setupAxios(axios);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
