import {
  CustomerUserAddress,
  CustomerUserAddressRequest,
  UpdateCustomerUserAddressRequest,
} from "../../../models/CustomerUserModel";
import {
  getAddressString,
  getZipString,
  validateValues,
} from "../../../util/helpers";
import { AddressFields } from "../../../components/AddressFields";
import * as yup from "yup";
import { useState, useEffect, ChangeEvent } from "react";
import { Address } from "../../checkout/model/CheckoutModel";

const schema = yup.object().shape({
  address1: yup.string().required("Street Address is required"),
  city: yup.string().required("City is required"),
  province_code: yup.string().required("State is required"),
  zip: yup.string().required("Zip Code is required"),
  fullname: yup.string().required("Fullname is required"),
});

export const ShippingAddressLine = ({
  name,
  address,
  onSaveUpdate,
  addresses,
}: {
  name: string;
  addresses: CustomerUserAddress[];
  address: CustomerUserAddress;
  onSaveUpdate: (req: UpdateCustomerUserAddressRequest) => Promise<boolean>;
}) => {
  const [displayAddress, setDisplayAddress] = useState(address);
  const [editAddress, setEditAddress] = useState<CustomerUserAddress>(address);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState<any>();
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [isAddressSaveButtonDisabled, setIsAddressSaveButtonDisabled] =
    useState<boolean>(false);

  const validateInputs = () =>
    validateValues(
      { ...editAddress.address, fullname: editAddress.fullname },
      schema,
      setErrors
    );

  const updateShippingAddress = async () => {
    const hasErrors = validateInputs();
    const uniqueAddress = addresses.some(
      (obj) => obj.address.address1 === editAddress.address.address1
    );

    if (uniqueAddress) {
      setErrors({ addressErr: "Selected address already exist" });
    }
    if (hasErrors || uniqueAddress) return;

    setIsAddressSaveButtonDisabled(true);
    const updated = await onSaveUpdate({
      id: editAddress.id,
      address_attributes: editAddress.address,
      default: editAddress.default,
      address_type: "shipping",
      fullname: editAddress.fullname,
    });
    if (updated) {
      setDisplayAddress({ ...editAddress });
      setShowAddressPopup(false);
    }
    setIsAddressSaveButtonDisabled(false);
  };

  const changeDefault = async (e: ChangeEvent<HTMLInputElement>) => {
    const updated = await onSaveUpdate({
      id: editAddress.id,
      address_attributes: editAddress.address,
      default: !editAddress.default,
      address_type: "shipping",
    });

    if (updated) {
      setEditAddress({ ...editAddress, default: !editAddress.default });
    }
  };

  useEffect(() => {
    setEditAddress(address);
    setDisplayAddress(address);
  }, [address]);

  return (
    <>
      <div
        className={`flex flex-grow flex-col ${showAddressPopup && "md:hidden"}`}
      >
        <div className="border-b px-4 py-4 flex gap-3">
          <input
            type="radio"
            name="profile_shipping_address"
            checked={editAddress.default}
            onChange={changeDefault}
            className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
          />
          <div>
            <p className="text-gray-700 tracking-wide text-xs font-bold font-mediumAvenir">
              {displayAddress.fullname}
            </p>
            <p className="mt-1 mb-1 text-gray-600 uppercase text-opacity-75 text-xs tracking-wide leading-tight">
              {getAddressString(displayAddress.address)}
              <br /> {getZipString(displayAddress.address)}
            </p>
            <div>
              <button
                className=" text-blue-theme text-xs font-medium cursor-pointer tracking-wide"
                onClick={() => {
                  setShowAddressPopup(true);
                  setEdit(true);
                  setEditAddress(address);
                }}
              >
                {" "}
                Edit Address
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex-col p-4 w-full hidden ${
          showAddressPopup && "md:block"
        }`}
      >
        <div className="flex justify-center">
          <div className="back-btn w-7 absolute left-5 cursor-pointer pt-4">
            <img
              src="back-arrow.svg"
              alt="back-icon"
              className="h-5 w-5"
              onClick={() => {
                setShowAddressPopup(false);
                setErrors({});
              }}
            />
          </div>
          <div className="title-wrap text-center mb-6">
            <h4 className="font-bold font-mediumAvenir tracking-wide pt-4">
              Edit Address
            </h4>
          </div>
          <div className="text-blue-600 text-sm font-medium absolute right-5 pt-4">
            {isAddressSaveButtonDisabled ? (
              <span>
                <Loading />
              </span>
            ) : (
              <button
                disabled={isAddressSaveButtonDisabled}
                onClick={() => updateShippingAddress()}
              >
                Save
              </button>
            )}
          </div>
        </div>
        {showAddressPopup && (
          <AddressFields
            errors={errors}
            initial={editAddress.address}
            onAddressChange={(address) => {
              setEditAddress({ ...editAddress, address: address });
            }}
            name={displayAddress.fullname}
            onNameChange={(newName) =>
              setEditAddress({ ...editAddress, fullname: newName })
            }
            edit={edit}
            showName={true}
          />
        )}
      </div>
      <div
        className={`address-popup shadow-drawer_shadow md:hidden ${
          showAddressPopup
            ? "show transform translate-y-1"
            : "transform translate-y-96"
        } bg-white fixed p-4 w-full max-h-96 left-0 h-auto transform  transition-all duration-500 bottom-0`}
      >
        <span className="mt-3.5 text-blue-600 text-sm font-medium">
          <div className="back-btn w-7 absolute left-5 top-5 cursor-pointer pt-2">
            <img
              onClick={() => {
                setShowAddressPopup(false);
                setErrors({});
              }}
              src="back-arrow.svg"
              alt="back-icon"
              className="h-5 w-5"
            ></img>
          </div>
          <div className="save-btn absolute right-5 top-5 ">
            {isAddressSaveButtonDisabled ? (
              <span>
                <Loading />
              </span>
            ) : (
              <button
                className="pt-2"
                disabled={isAddressSaveButtonDisabled}
                onClick={() => updateShippingAddress()}
              >
                Save
              </button>
            )}
          </div>
        </span>
        <div className="popup-title text-center">
          <h3 className="font-bold font-mediumAvenir tracking-wide pt-2">
            Address
          </h3>
          <div className="mt-5 mb-5">
            {showAddressPopup && (
              <AddressFields
                errors={errors}
                initial={editAddress.address}
                onAddressChange={(address) => {
                  setEditAddress({ ...editAddress, address: address });
                }}
                name={displayAddress.fullname}
                edit={edit}
                onNameChange={(newName) =>
                  setEditAddress({ ...editAddress, fullname: newName })
                }
                showName={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const Loading = () => {
  return (
    <div
      style={{ borderTopColor: "transparent" }}
      className="w-8 h-8 border-4 border-blue-400 border-solid rounded-full animate-spin"
    />
  );
};
export const AddNewShippingAddressLine = ({
  onSave,
  addresses,
  refresh,
}: {
  onSave: (req: CustomerUserAddressRequest) => Promise<boolean>;
  refresh: () => void;
  addresses: CustomerUserAddress[];
}) => {
  const initialAddress = {
    fullname: "",
    address1: "",
    address2: "",
    city: "",
    country_code: "",
    province_code: "",
    zip: "",
  };
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [newAddress, setNewAddress] = useState<Address>(initialAddress);
  const [fullname, setFullname] = useState<string>("");

  const [errors, setErrors] = useState<any>();
  const [isAddressSaveButtonDisabled, setIsAddressSaveButtonDisabled] =
    useState<boolean>(false);

  const saveShippingAddress = async () => {
    if (!newAddress) return;
    // if (!fullname) return;

    const hasErrors = validateValues(
      { ...newAddress, fullname },
      schema,
      setErrors
    );

    const uniqueAddress = addresses.some(
      (obj) => obj.address.address1 === newAddress.address1
    );

    if (uniqueAddress) {
      setErrors({ addressErr: "Selected address already exist" });
    }

    if (hasErrors || uniqueAddress) return;

    setIsAddressSaveButtonDisabled(true);

    const saved = await onSave({
      address_attributes: newAddress,
      address_type: "shipping",
      default: true,
      fullname,
    });

    if (saved) {
      setShowAddressPopup(false);
      // setNewAddress(initialAddress);
      setNewAddress({ ...initialAddress });
      refresh();
    }
    setIsAddressSaveButtonDisabled(false);
  };

  return (
    <>
      <div
        className={`flex justify-between px-4 py-4 tracking-wide text-xs font-medium cursor-pointer ${
          showAddressPopup && "md:hidden"
        }`}
        onClick={() => setShowAddressPopup(true)}
      >
        <span>Add a new shipping address</span>
        <span>{">"}</span>
      </div>
      <div
        className={`flex-col p-4 w-full hidden ${
          showAddressPopup && "md:block"
        }`}
      >
        <div className="flex justify-center">
          <button className="back-btn w-7 absolute left-5 cursor-pointer">
            <img
              src="back-arrow.svg"
              alt="back-icon"
              className="h-5 w-5"
              onClick={() => {
                setShowAddressPopup(false);
                setNewAddress({ ...initialAddress });
                setErrors({});
              }}
            />
          </button>
          <div className="title-wrap text-center mb-6">
            <h4 className="font-bold font-mediumAvenir tracking-wide">
              New Address
            </h4>
          </div>
          <div className="text-blue-600 text-sm font-medium absolute right-5">
            {isAddressSaveButtonDisabled ? (
              <span>
                <Loading />
              </span>
            ) : (
              <button
                disabled={isAddressSaveButtonDisabled}
                onClick={() => saveShippingAddress()}
              >
                Save
              </button>
            )}
          </div>
        </div>
        {showAddressPopup && (
          <AddressFields
            errors={errors}
            onAddressChange={(address) => {
              setNewAddress(address);
            }}
            edit={false}
            initial={newAddress}
            showName={true}
            onNameChange={setFullname}
          />
        )}
      </div>
      <div
        className={`address-popup shadow-drawer_shadow md:hidden ${
          showAddressPopup
            ? "show transform translate-y-1"
            : "transform translate-y-96"
        } bg-white fixed p-4 w-full max-h-96 left-0 h-auto transform  transition-all duration-500 bottom-0`}
      >
        <span className="mt-3.5 text-blue-600 text-sm font-medium">
          <div className="back-btn w-7 absolute left-5 top-5 cursor-pointer">
            <img
              onClick={() => setShowAddressPopup(false)}
              src="back-arrow.svg"
              alt="back-icon"
              className="h-5 w-5"
            ></img>
          </div>
          <div className="save-btn absolute right-5 top-5">
            {isAddressSaveButtonDisabled ? (
              <span>
                <Loading />
              </span>
            ) : (
              <button
                disabled={isAddressSaveButtonDisabled}
                onClick={() => saveShippingAddress()}
              >
                Save
              </button>
            )}
          </div>
        </span>
        <div className="popup-title">
          <div className="text-center">
            <h3 className="font-bold font-mediumAvenir tracking-wide">
              Address
            </h3>
          </div>
          <div className="mt-5 mb-5">
            {showAddressPopup && (
              <AddressFields
                errors={errors}
                onAddressChange={(address) => {
                  setNewAddress(address);
                }}
                edit={false}
                initial={newAddress}
                showName={true}
                onNameChange={setFullname}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
