import axios from "axios";
import {
  CreateReminderReq,
  RepeatReminderResponse,
  UpdateReminderReq,
} from "../model/ReminderModel";

const API_URL = process.env.REACT_APP_API_URL;

export const getReminder = (uuid: string) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}`);
  return axios.get<RepeatReminderResponse>(url.toString());
};

export const getReminders = (phone: string) => {
  const url = new URL(`${API_URL}/customer_users/${phone}/repeat_reminders`);
  return axios.get<RepeatReminderResponse[]>(url.toString());
};

export const updateReminder = (uuid: string, updateReq: UpdateReminderReq) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}`);
  return axios.put<RepeatReminderResponse>(url.toString(), {
    repeat_reminder: updateReq,
  });
};

export const createReminder = (phone: string, req: CreateReminderReq) => {
  const url = new URL(`${API_URL}/customer_users/${phone}/repeat_reminders`);
  return axios.post<RepeatReminderResponse>(url.toString(), {
    repeat_reminder: req,
  });
};

export const pauseReminder = (uuid: string) => {
  const url = new URL(`${API_URL}/repeat_reminders/${uuid}/pause`);
  return axios.put<RepeatReminderResponse>(url.toString());
};
