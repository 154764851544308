import { ChangeEvent, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { purchaseCartV2 } from "../api/CartsV2Requests";
import { Address, Checkout } from "../../checkout/model/CheckoutModel";
import {
  CustomerUserResponse,
  CustomerUserAddress,
} from "../../../models/CustomerUserModel";
import { CartContext } from "../../../contexts/CartContext";
import { AddressFields } from "../../../components/AddressFields";
import { ShippingDetailList } from "./CartPrice";
import { ShipTo, saveAddress } from "./CartDetails";
import { ProductPageConfiguration } from "../../../models/ConfigurationModel";
import { FormLoader } from "../../../components/Loading";
import { updateAddress } from "../../../api/customerUserApi";
import { SiteConfigurationContext } from "../../../contexts/SiteConfigurationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomerUserContext } from "../../../contexts/CustomerUserContext";

const { REACT_APP_API_AUDIENCE: audience } = process.env;

export const CartView = ({
  cu,
  totalCartItems,
  cartPrice,
  config,
  setCartPrice,
  updateCustomerUser,
  overrideReturnTo,
  discounts,
}: {
  cu: CustomerUserResponse | null;
  totalCartItems: number;
  cartPrice: string;
  config?: ProductPageConfiguration;
  setCartPrice: (value: string) => void;
  updateCustomerUser: () => void;
  overrideReturnTo?: string;
  discounts?: { [key: string]: string };
}) => {
  const { siteConfiguration } = useContext(SiteConfigurationContext);

  const { cart, updateCart } = useContext(CartContext);

  const { customerUser, refresh, loading } = useContext(CustomerUserContext);

  const { getAccessTokenSilently } = useAuth0();

  const [processing, setProcessing] = useState(false);
  const [cartId] = useState<string>();
  const qtyPickerOptions: any[] = [];
  const [currentEditItem, setCurrentEditItem] = useState("");
  const [showPopup, setShowPopup] = useState<Boolean>(false);
  const [showNewAddress, setShowNewAddress] = useState<boolean>(false);
  const [defaultId, setDefaultId] = useState<number>();
  const [errors, setErrors] = useState<any>();
  const [addresses, setAddresses] = useState<CustomerUserAddress[]>([]);
  const initialAddress = {
    address1: "",
    address2: "",
    city: "",
    country_code: "",
    province_code: "",
    zip: "",
  };
  const [newAddress, setNewAddress] = useState<Address>({ ...initialAddress });
  const [fullname, setFullname] = useState<string>();

  const [isAddressSaveButtonDisabled, setIsAddressSaveButtonDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (customerUser && !loading) {
      setAddresses(customerUser.shipping_addresses);
      setDefaultId(customerUser.default_shipping_address?.id);
    }
  }, [customerUser, loading]);

  const setDefaultAddress = (id: number, index: number) => {
    if (customerUser?.phone) {
      updateAddress(
        customerUser?.phone,
        { id, default: true },
        customerUser?.uuid
      );
      const updated = [...addresses];
      updated.splice(index, 1, { ...updated[index], default: true });
      setAddresses(updated);
      refresh();
    }
  };

  for (let i: number = 0; i < 20; i++) {
    qtyPickerOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  // const onSubmit = (c: CartResponse) => {
  //   setCartId(c.uuid);
  // };

  const purchase = async (event: any) => {
    event.preventDefault();
    const lineItems = cart.lineItems.map((p) => ({
      variantId: p.variant.variant_id,
      quantity: p.quantity,
    }));
    let shippingAddress = null;
    let billingAddress = null;
    if (
      customerUser != null &&
      customerUser.default_shipping_address != null &&
      customerUser.default_shipping_address.address != null
    ) {
      shippingAddress = customerUser.default_shipping_address.address;
      billingAddress = customerUser.default_shipping_address.address;
    }

    if (!customerUser || !customerUser.default_shipping_address) {
      return;
    }

    const co: Checkout = {
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
      lineItems,
      phone: customerUser?.phone,
      utmTags: window.location.search.substr(1),
    };

    setProcessing(true);

    getAccessTokenSilently({ audience })
      .then((accessToken) =>
        purchaseCartV2(
          { ...co, productPageConfigurationId: `${config?.id}` },
          accessToken
        )
      )
      .then(({ data }) => {
        setProcessing(false);
        window.location.href = data.invoice_url;
        // onSubmit(data);
      })
      .catch((r) => {
        if (customerUser) {
          purchaseCartV2(
            { ...co, productPageConfigurationId: `${config?.id}` },
            customerUser.uuid
          ).then(({ data }) => {
            setProcessing(false);
            window.location.href = data.invoice_url;
            // onSubmit(data);
          });
        } else {
          setProcessing(false);
        }
      });
  };

  const handleQuantityChange = (
    e: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    updateCart({
      variant: cart.lineItems[index].variant,
      quantity: Number(e.target.value),
    });
  };

  const handleProductRemove = (index: number) => {
    updateCart({ variant: { ...cart.lineItems[index].variant }, quantity: 0 });
  };

  const onSaveAddress = async () => {
    // const accessToken = await getAccessTokenSilently();

    if (customerUser && fullname) {
      const accessToken = customerUser.uuid;
      await saveAddress({
        customerUser,
        newAddress,
        setErrors,
        updateCustomerUser: refresh,
        setAddresses,
        setDefaultId,
        setShowNewAddress,
        setIsAddressSaveButtonDisabled,
        accessToken,
        fullname,
      });
    }

    setNewAddress({ ...initialAddress });
  };

  const cartHasProducts = () => {
    return cart.lineItems.length > 0;
  };

  const Loading = () => {
    return (
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-8 h-8 border-4 border-blue-400 border-solid rounded-full animate-spin m-auto"
      />
    );
  };

  if (cartId && config) {
    return <Navigate to={`/order/${cartId}`} />;
  }

  // let returnTo = `/checkout/${config?.id}`;

  if (overrideReturnTo) {
    // returnTo = `${returnTo}?reminder=${overrideReturnTo}`;
  }

  const hasAnyDiscount = () => discounts && Object.keys(discounts).length > 0;

  return (
    <>
      <div
        className={`cart-summary-wrap lg:max-w-xs max-w-none lg:w-full w-4/12 border border-designerGray border-opacity-20 rounded-md relative z-10 xl:ml-10 ml-5 bg-white overflow-hidden md:block hidden h-full ${
          cartHasProducts() ? "pb-32" : "pb-6 "
        } `}
      >
        <div className="col-title">
          <h3 className="text-gray-variant text-sm tracking-wide uppercase font-black font-blackAvenir border-b border-designerGray border-opacity-10 p-3">
            Your Cart
          </h3>
        </div>
        {!cartHasProducts() ? (
          <div className="cart-empty-wrap py-6 px-4">
            <h3 className="text-xs text-black_variant font-bold font-mediumAvenir mb-2">
              Your Cart is Empty
            </h3>
            <p className="text-gray-variant3 text-xs">
              Looks like you haven’t added anything yet. What are you running
              low on?
            </p>
          </div>
        ) : (
          <>
            <div className="cart-item-wrap pt-6 px-4">
              {cart?.lineItems?.map(({ variant, quantity }, index) => (
                <div
                  className="single-item border-b border-black border-opacity-10 py-4"
                  key={index}
                >
                  <div className="top-row flex flex-row pb-4 flex-wrap">
                    <div className="product-img w-1/6">
                      <img
                        className="w-12 h-12 border border-borderGrey2-greyb2 rounded-md object-contain"
                        src={variant?.image_url}
                        alt=""
                      ></img>
                    </div>
                    <div className="product-detail-wrap pl-3 flex justify-between w-5/6">
                      <div className="product-name-detail">
                        <h3 className="text-sm font-extrabold text-designerGray">
                          {variant?.name?.length > 20
                            ? variant?.name?.substring(0, 20) + "..."
                            : variant?.name}
                        </h3>
                        <span className="text-gray-variant3 text-xs">
                          {variant?.variant_name === "Default Title"
                            ? " "
                            : variant?.variant_name}
                        </span>
                      </div>
                      <div className="price-wrap text-sm font-medium font-mediumAvenir">
                        $
                        {discounts &&
                        +discounts[variant?.["main_product_id"]] > 0
                          ? discounts[variant?.["main_product_id"]]
                          : (variant?.price * quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="btm-row flex justify-between items-center">
                    <div className="drpdwn-wrap flex-row flex text-xs w-full items-center">
                      <label className="select-label relative mr-3">
                        <select
                          onChange={(e) => {
                            handleQuantityChange(e, index);
                          }}
                          name="Size"
                          id="Size"
                          className="py-2 px-2 border block w-14 bg-white md:rounded-md md:py-2 md:px-3 border-designerGray md:border-opacity-50 text-xs"
                          value={quantity}
                        >
                          {qtyPickerOptions}
                        </select>
                      </label>
                      in cart
                    </div>
                    <button
                      className="text-xs text-blue1 w-full text-right"
                      onClick={() => {
                        handleProductRemove(index);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="promocode-wrap border-b border-black border-opacity-10 px-4 pb-4 flex">
              <input
                className="w-3/4 border border-designerGray rounded-md rounded-r-none p-3 py-2 text-xs h-12"
                type="text"
                placeholder="Promo code or gift card"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              ></input>
              <div className="w-1/4 pl-0">
                <button
                  className="black-btn w-full rounded-l-none"
                  onClick={() => setDiscountCode(code)}
                >
                  Add
                </button>
              </div>
            </div> */}
            {customerUser && (
              <div className="payment-method-wrap p-4 pb-0">
                <ShippingDetailList
                  customerUser={customerUser}
                  setCurrentEditItem={(newItem) => {
                    setCurrentEditItem(newItem);
                    if (!newItem) {
                      setShowPopup(false);
                    } else {
                      setShowPopup(true);
                    }
                  }}
                />
              </div>
            )}
            <div className="summary-footer-wrap  absolute bottom-0 w-full">
              <div className="btn-wrap">
                <div className="checkout-btn px-4">
                  {/* {!customerUser ? (
                    <button
                      className="py-4 px-4 text-white block rounded-md text-sm text-center w-full tracking-wide pb-4"
                      value="checkout"
                      style={{
                        backgroundColor: siteConfiguration.primary_color,
                      }}
                      onClick={() =>
                        navigate(`/auth?redirectTo=${window.location.pathname}`)
                      } // from router 6
                    >
                      Check Out
                    </button>
                  ) : (
                    !processing && (
                      <button
                        className="py-4 px-4 text-white block rounded-md text-sm text-center w-full tracking-wide pb-4"
                        value="checkout"
                        style={{
                          backgroundColor: siteConfiguration.primary_color,
                        }}
                        onClick={purchase}
                      >
                        Check Out
                      </button>
                    )
                  )} */}
                  {processing ? (
                    <div>
                      <Loading />
                    </div>
                  ) : (
                    <button
                      className="py-4 px-4 text-white block rounded-md text-sm text-center w-full tracking-wide pb-4"
                      value="checkout"
                      style={{
                        backgroundColor: siteConfiguration.primary_color,
                      }}
                      onClick={purchase}
                    >
                      Check Out
                    </button>
                  )}
                </div>
              </div>
              <div className="bottom-0 left-0 right-0 shadow-tmd p-3 bg-white">
                <div className="flex justify-between items-center gap-4 relative max-w-960 mx-auto">
                  <div>
                    <svg
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H5C5.35747 0.25 5.66526 0.502289 5.73541 0.852807L6.61551 5.25H23C23.2235 5.25 23.4354 5.3497 23.5779 5.52192C23.7204 5.69414 23.7786 5.92093 23.7367 6.1405L22.1357 14.5361C22.0099 15.1692 21.6655 15.7378 21.1628 16.1425C20.662 16.5456 20.0359 16.7605 19.3934 16.75H9.68664C9.04405 16.7605 8.41799 16.5456 7.91725 16.1425C7.41473 15.738 7.07043 15.1697 6.94455 14.537L5.27255 6.18328C5.26672 6.16005 5.26197 6.13639 5.25836 6.11235L4.38524 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM6.91573 6.75L8.41563 14.2439C8.47278 14.5316 8.62932 14.7901 8.85785 14.9741C9.08637 15.158 9.37232 15.2558 9.66563 15.2501L9.68 15.25H19.4L19.4144 15.2501C19.7077 15.2558 19.9936 15.158 20.2222 14.9741C20.45 14.7906 20.6063 14.5331 20.6639 14.2463L20.6644 14.2439L22.0935 6.75H6.91573ZM7.25003 21C7.25003 20.0335 8.03353 19.25 9.00003 19.25C9.96653 19.25 10.75 20.0335 10.75 21C10.75 21.9665 9.96653 22.75 9.00003 22.75C8.03353 22.75 7.25003 21.9665 7.25003 21ZM18.25 21C18.25 20.0335 19.0335 19.25 20 19.25C20.9665 19.25 21.75 20.0335 21.75 21C21.75 21.9665 20.9665 22.75 20 22.75C19.0335 22.75 18.25 21.9665 18.25 21Z"
                        fill="#2F363D"
                      ></path>
                    </svg>
                    <div className="bg-designerOrange absolute left-4 top-0 h-5 w-5 rounded-full flex items-center justify-center">
                      <p className="text-white text-sm">{totalCartItems}</p>
                    </div>
                  </div>
                  <div className="text-right flex-1">
                    <div className="flex justify-end">
                      {hasAnyDiscount() && +cartPrice > 0 ? (
                        <>
                          <span className="text-xs relative mr-2 inline-flex items-center">
                            <span className="line-through absolute"></span>$
                            {cart.lineItems.reduce((p: number, c: any) => {
                              p = p + +c.variant.price * c.quantity;
                              return p;
                            }, 0)}
                          </span>

                          <p className="font-bold font-mediumAvenir text-sm tracking-wide text-blue-500">
                            ${cartPrice}
                          </p>
                        </>
                      ) : (
                        <p className="font-bold font-mediumAvenir text-sm tracking-wide">
                          ${cartPrice}
                        </p>
                      )}
                    </div>
                    <p className="text-xs text-grey-variant2">
                      {/* {customerUser
                        ? "with tax & shipping"
                        : "before tax & shipping"} */}
                      Before tax & shipping
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className={`modal animated fadeInUp fixed bg-smoke-dark h-full w-full top-0 left-0 flex transition-all ease-in-out transform duration-500  ${
            showPopup ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <div className="overlay absolute h-full w-full bg-black opacity-70"></div>
          <div
            className={` modal-inner transition ease-in-out animated fadeInUp max-w-md md:relative pin-b pin-x m-auto justify-center py-8 bg-white rounded w-full shadow transform duration-1000 ${
              showPopup ? "translate-y-0" : "-translate-y-2/4"
            }`}
          >
            <div
              className="close-btn absolute shadow flex items-center justify-center w-8 h-8 text-cyan-500 border rounded-full bg-white cursor-pointer"
              onClick={() => {
                setShowPopup(false);
                setTimeout(() => {
                  setCurrentEditItem("");
                }, 500);
                setShowNewAddress(false);
              }}
            >
              <img
                src="\close-btn-blue.svg"
                alt=""
                className="w-3 h-3 cursor-pointer"
              ></img>
            </div>
            {currentEditItem === "ship_to" && (
              <>
                <h4 className="text-base font-extrabold text-designerGray text-center mb-3">
                  Ship To
                </h4>
                <div className="px-8">
                  {customerUser && (
                    <ShipTo
                      addresses={addresses}
                      customerUser={customerUser}
                      defaultId={defaultId}
                      setDefaultId={setDefaultAddress}
                      viewFrom="desktop"
                    />
                  )}
                </div>
                <div
                  className={`add-new flex justify-between mt-4 px-8 ${
                    showNewAddress && "md:hidden"
                  }`}
                  onClick={() => setShowNewAddress(true)}
                >
                  <h3 className="text-base text-designerGray tracking-wide font-medium cursor-pointer">
                    Add a new shipping addresss
                  </h3>
                  <img src="\right-angle.svg" alt=""></img>
                </div>
                {showNewAddress && (
                  <div className="hidden md:block px-8 pt-3 shadow-drawer_shadow">
                    <div className="flex justify-between items-center mt-2">
                      <div
                        className="text-blue-600 text-sm font-medium w-7 relative left-0 cursor-pointer"
                        onClick={() => {
                          setShowNewAddress(false);
                          setNewAddress({ ...initialAddress });
                        }}
                      >
                        Cancel
                      </div>
                      <div className="title-wrap text-center">
                        <h4 className="font-bold font-mediumAvenir tracking-wide">
                          Add New Address
                        </h4>
                      </div>
                      <div className="text-blue-600 text-sm font-medium relative right-0">
                        {isAddressSaveButtonDisabled ? (
                          <FormLoader />
                        ) : (
                          <button
                            disabled={isAddressSaveButtonDisabled}
                            onClick={() => onSaveAddress()}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <AddressFields
                        initial={newAddress}
                        onAddressChange={(address: Address) =>
                          setNewAddress({ ...newAddress, ...address })
                        }
                        edit={false}
                        errors={errors}
                        showName={true}
                        onNameChange={setFullname}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
