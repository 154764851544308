import React from "react";

const Footer = () => {
  return (
    <>
      <div className="summary-footer-wrap absolute bottom-0 p-2 w-full">
        <div className="w-full flex justify-center relative items-center">
          <span className="mr-1 pr-2">Powered by</span>
          <img className="object-contain h-8" src="\assistalong 2.svg" alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
