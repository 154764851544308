import axios from "axios";
import {
  CustomerUserResponse,
  GetCustomerUserRequest,
  CustomerUserReqBody,
  PurchaseHistoryResponse,
  OrderHistoryResponse,
  SavedProductsResponse,
  SetupIntent,
  CustomerUserAddressRequest,
  CustomerUserAddress,
  UpdateCustomerUserAddressRequest,
} from "../models/CustomerUserModel";
import { useApi, UseAPI } from "./use-api";

const API_URL = process.env.REACT_APP_API_URL;
const endpoint = "customer_users";

const baseUrl = (phone: string) =>
  `${API_URL}/${endpoint}/${encodeURIComponent(phone)}`;

export const getCustomerUser = (req: GetCustomerUserRequest) =>
  axios.get<CustomerUserResponse>(`${baseUrl(req.phone)}`);

export function useGetCustomerUser(): UseAPI<CustomerUserResponse> {
  return useApi<CustomerUserResponse>({
    method: "get",
    url: `${API_URL}/${endpoint}`,
  });
}

export const getCustomerUsers = (accessToken: string) =>
  axios.get<CustomerUserResponse>(`${API_URL}/${endpoint}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const updateCustomerUser = (
  phone: string,
  body: CustomerUserReqBody,
  accessToken: string
) =>
  axios.put<CustomerUserResponse>(
    `${baseUrl(phone)}`,
    { customer_user: body },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );

export const getPurchaseHistory = (phone: string, accessToken: string) =>
  axios.get<PurchaseHistoryResponse>(`${baseUrl(phone)}/purchase_history`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const getPurchasedProducts = (phone: string, accessToken: string) =>
  axios.get<PurchaseHistoryResponse>(`${baseUrl(phone)}/purchased`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const getSavedProducts = (phone: string, accessToken: string) =>
  axios.get<SavedProductsResponse>(`${baseUrl(phone)}/saved`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const saveProduct = (phone: string, variantId: string) => {
  const url = new URL(`${baseUrl(phone)}/save_variant`);
  url.searchParams.append("variant", variantId);
  return axios.post<SavedProductsResponse>(url.toString());
};

export interface OrderHistoryDate {
  day: number;
  month: number;
  year: number;
}

// For this API, you can filter orders between date range with the range being [from..to] inclusive
// If you dont provide either parameter, then all orders are returned
// If you provide just the from parameter, the range will be [from..now] inclusive
// If you provide just the to parmameter, the API will give you an error
export const getOrderHistory = (
  phone: string,
  accessToken: string,
  from?: Date,
  to?: Date
) => {
  const url = new URL(`${baseUrl(phone)}/order_history`);

  if (from) {
    url.searchParams.append("from", from.toISOString());
  }
  if (to) {
    url.searchParams.append("to", to.toISOString());
  }

  return axios.get<OrderHistoryResponse>(url.toString(), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getSetupIntent = (phone: string, accessToken: string) =>
  axios.get<SetupIntent>(
    `${API_URL}/${endpoint}/${encodeURIComponent(phone)}/setup_payment_method`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );

export const attachPaymentMethod = (
  phone: string,
  payment_method_id: string,
  accessToken: string
) =>
  axios.post<CustomerUserResponse>(
    `${API_URL}/${endpoint}/${encodeURIComponent(phone)}/attach_payment_method`,
    { payment_method_id },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );

export const addAddress = (
  phone: string,
  address: CustomerUserAddressRequest,
  accessToken: string
) =>
  axios.post<CustomerUserAddress>(
    `${API_URL}/${endpoint}/${encodeURIComponent(
      phone
    )}/customer_user_addresses`,
    { address: address },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );

export const updateAddress = (
  phone: string,
  request: UpdateCustomerUserAddressRequest,
  accessToken: string
) =>
  axios.put<CustomerUserAddress>(
    `${API_URL}/${endpoint}/${encodeURIComponent(
      phone
    )}/customer_user_addresses/${request.id}`,
    { address: request },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
