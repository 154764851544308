import { useNavigate, useParams } from "react-router-dom";

const SmartCheckoutRedirect = () => {
  // possible smart checkout urls
  // second param could be customer ID (aad7d137-56ad-4ddf-b913-4fc4e8fc3dd0) OR event url ({https://assistalong.app/anything?anyparam=anyvalue})

  // 2. with brandname, customer uuid and smartcheckout name:
  // ex. https://asistalong.app/my-brand/aad7d137-56ad-4ddf-b913-4fc4e8fc3dd0/my-smart-checkout

  // 3. with brand name, customer uuid, smartcheckout name and product id:
  // ex. https://asistalong.app/my-brand/aad7d137-56ad-4ddf-b913-4fc4e8fc3dd0/my-smart-checkout/23

  const params = useParams();
  const navigate = useNavigate();

  let rest = params["*"];
  if (rest?.includes("{") && rest?.includes("}")) {
    rest = rest.replace("{", "");
    const urlToRedirect = rest.substring(0, rest.lastIndexOf("}"));
    // check if url is valid

    if (
      urlToRedirect.startsWith("http:") ||
      urlToRedirect.startsWith("https:")
    ) {
      window.location.href = urlToRedirect;
      return <></>;
    } else {
      const withoutCurlyStringParam =
        params["*"]?.replace(urlToRedirect, "")?.split("/") || [];
      const [, smartCheckoutName, productId] = withoutCurlyStringParam;
      if (productId) {
        window.location.href = `https://${params.brandName}.assistalong.app/smart-checkout/null/${smartCheckoutName}/${productId}`;
      } else {
        window.location.href = `https://${params.brandName}.assistalong.app/smart-checkout/null/${smartCheckoutName}`;
      }
    }
  } else {
    const restOfParams = rest?.split("/") || [];
    const [cuuid, smartCheckoutName, productId] = restOfParams;
    if (productId) {
      window.location.href = `https://${params.brandName}.assistalong.app/smart-checkout/${cuuid}/${smartCheckoutName}/${productId}`;
    } else {
      window.location.href = `https://${params.brandName}.assistalong.app/smart-checkout/${cuuid}/${smartCheckoutName}`;
    }
    return <></>;
  }

  return (
    <div className="flex min-h-screen w-full flex-col justify-center items-center px-4 xl:px-0">
      <div className="">
        <div className="text-2xl md:!text-6xl font-semibold mb-6 xl:!mb-14">
          404 - Smart checkout not found
        </div>
        <div className="">
          <button
            className="cursor-pointer text-sm rounded py-2 px-4 bg-blue hover:bg-blue-800 text-white font-medium"
            onClick={() => navigate("/")}
          >
            Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default SmartCheckoutRedirect;
