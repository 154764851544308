import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { Address } from "../pages/checkout/model/CheckoutModel";
import { getClass, onInputBlur, onInputFocus } from "../util/helpers";

export const AddressFields = ({
  initial,
  name,
  edit,
  onAddressChange,
  onNameChange,
  errors = {},
  showName = false,
}: {
  initial: Address;
  name?: string;
  edit: boolean;
  onAddressChange: (a: Address) => void;
  onNameChange?: (n: string) => void;
  errors: any;
  showName?: boolean;
}) => {
  const [address, setAddress] = useState<Address>({
    ...initial,
  });

  const [fullname, setFullname] = useState(name);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (edit) {
      setChange(edit);
    }
  }, [edit]);

  const [focusedFields, setFocusedFields] = useState<string[]>([]);

  const onPlaceSelected = (place: any) => {
    var streetAddress = "";
    const initialData = {
      address1: "",
      address2: "",
      city: "",
      country_code: "",
      province_code: "",
      zip: "",
    };
    // Map across the place components and turn into parts of the address
    place?.address_components?.forEach(
      ({
        long_name,
        short_name,
        types,
      }: {
        long_name: string;
        short_name: string;
        types: string[];
      }) => {
        if (types.includes("street_number")) {
          streetAddress = short_name;
        } else if (types.includes("route")) {
          streetAddress += ` ${long_name}`;
          initialData.address1 = streetAddress;
        } else if (types.includes("locality")) {
          initialData.city = long_name;
        } else if (types.includes("administrative_area_level_1")) {
          initialData.province_code = short_name;
        } else if (types.includes("postal_code")) {
          initialData.zip = long_name;
        } else if (types.includes("country")) {
          initialData.country_code = short_name;
        }
      }
    );

    setAddress(initialData);
    onAddressChange(initialData);
  };

  const onAddressPartChange = (newAddress: Address) => {
    if (newAddress.address1 === "") {
      setChange(!change);
    }
    setAddress(newAddress);
    // console.log(newAddress);
    onAddressChange({ ...newAddress });
  };

  const onNameUpdate = (newName: string) => {
    setFullname(newName);

    if (onNameChange) {
      onNameChange(newName);
    }
  };

  return (
    <div>
      {showName && (
        <>
          <div
            className={`field-wrap mt-2 ${getClass(
              "fullname",
              { ...initial, fullname },
              focusedFields
            )}`}
          >
            <label className="input-label">Full Name</label>
            <input
              type="text"
              id="fullname"
              autoComplete="off"
              className="input-field"
              value={fullname}
              onFocus={() =>
                onInputFocus(focusedFields, setFocusedFields, "fullname")
              }
              onBlur={() =>
                onInputBlur(focusedFields, setFocusedFields, "fullname")
              }
              onChange={(e) => onNameUpdate(e.target.value)}
            />
          </div>
          <p className="text-danger text-xs">{errors["fullname"]}</p>
        </>
      )}
      <div
        className={`field-wrap mt-2 ${getClass(
          "address1",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Street Address</label>
        {!change ? (
          <Autocomplete
            apiKey="AIzaSyCF4OG3WNQ0cwSJVpz4qdmZGrCaBdbyXqo"
            onPlaceSelected={onPlaceSelected}
            className="input-field"
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
            onFocus={() =>
              onInputFocus(focusedFields, setFocusedFields, "address1")
            }
            onBlur={(e) => {
              onInputBlur(focusedFields, setFocusedFields, "address1");
              onAddressPartChange({ ...address, address1: e.target.value });
            }}
            placeholder=""
          />
        ) : (
          <input
            required
            type="text"
            id="address-line1"
            autoComplete="off"
            className="input-field"
            value={address.address1}
            onFocus={() =>
              onInputFocus(focusedFields, setFocusedFields, "address1")
            }
            onBlur={() => {
              onInputBlur(focusedFields, setFocusedFields, "address1");
            }}
            onChange={(e) => {
              onAddressPartChange({ ...address, address1: e.target.value });
            }}
          />
        )}
      </div>
      <p className="text-danger text-xs">{errors["address1"]}</p>
      <div
        className={`field-wrap mt-2 ${getClass(
          "address2",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Apartment, Building, Floor</label>
        <input
          type="text"
          id="address-line2"
          autoComplete="off"
          className="input-field"
          value={address.address2}
          onFocus={() =>
            onInputFocus(focusedFields, setFocusedFields, "address2")
          }
          onBlur={() =>
            onInputBlur(focusedFields, setFocusedFields, "address2")
          }
          onChange={(e) =>
            onAddressPartChange({ ...address, address2: e.target.value })
          }
        />
      </div>

      <div className="flex flex-row mt-2 w-full gap-1">
        <div
          className={`field-wrap w-1/2 ${getClass(
            "city",
            initial,
            focusedFields
          )}`}
        >
          <label className="input-label">City</label>
          <input
            required
            type="text"
            id="city"
            autoComplete="off"
            className="input-field"
            value={address.city}
            onFocus={() =>
              onInputFocus(focusedFields, setFocusedFields, "city")
            }
            onBlur={() => onInputBlur(focusedFields, setFocusedFields, "city")}
            size={15}
            onChange={(e) =>
              onAddressPartChange({ ...address, city: e.target.value })
            }
          />
          <p className="text-danger error-flex-box">{errors["city"]}</p>
        </div>

        <div
          className={`field-wrap w-1/4 ${getClass(
            "province_code",
            initial,
            focusedFields
          )}`}
        >
          <label className="input-label">State</label>
          <input
            required
            type="text"
            id="state"
            autoComplete="off"
            maxLength={2}
            minLength={2}
            size={10}
            value={address.province_code}
            className="input-field"
            onFocus={() =>
              onInputFocus(focusedFields, setFocusedFields, "province_code")
            }
            onBlur={() =>
              onInputBlur(focusedFields, setFocusedFields, "province_code")
            }
            onChange={(e) =>
              onAddressPartChange({ ...address, province_code: e.target.value })
            }
          />
          <p className="text-danger error-flex-box">
            {errors["province_code"]}
          </p>
        </div>

        <div
          className={`flex-1 field-wrap w-1/4 flex-col flex ${getClass(
            "zip",
            initial,
            focusedFields
          )}`}
        >
          <label className="input-label">Zipcode</label>
          <input
            required
            type="text"
            id="postal-code"
            autoComplete="off"
            className="input-field"
            value={address.zip}
            maxLength={5}
            size={11}
            onFocus={() => onInputFocus(focusedFields, setFocusedFields, "zip")}
            onBlur={() => onInputBlur(focusedFields, setFocusedFields, "zip")}
            onChange={(e) =>
              onAddressPartChange({ ...address, zip: e.target.value })
            }
          />
          <p className="text-danger error-flex-box">{errors["zip"]}</p>
        </div>
      </div>
      <p className="text-danger text-center text-xs pt-2">
        {errors["addressErr"]}
      </p>
    </div>
  );
};
