import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBrands } from "../../api/ConfigurationRequests";
import { Loading } from "../../components/Loading";
import NavBar2 from "../../components/Navbar2";
import { BrandConfiguration } from "../../models/ConfigurationModel";

const BrandIndex = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState<BrandConfiguration[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const businessId = process.env.REACT_APP_BUSINESS_ID;

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line
  }, []);

  const getBrands = async () => {
    if (businessId) {
      setIsLoading(true);
      await getAllBrands()
        .then((res) => {
          setIsLoading(false);
          if (res.data && res.data.brands) {
            setBrands(res.data.brands);
          } else {
            setError("Brands not found");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError("Brands not found");
        });
    }
  };

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (error) {
    return (
      <div className="flex min-h-screen w-full flex-col justify-center items-center px-4 xl:px-0">
        <div className="">
          <div className="text-2xl md:!text-6xl font-semibold mb-6 xl:!mb-14">
            404 - Products not found
          </div>
          <div className="">
            <button
              className="cursor-pointer text-sm rounded py-2 px-4 bg-blue hover:bg-blue-800 text-white font-medium"
              onClick={() => navigate("/")}
            >
              Homepage
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <NavBar2
        middle={
          <div className="flex relative pl-4 w-full py-4 bg-white sm:justify-end justify-between h-16">
            <img
              className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8"
              src="\logo512.png"
              key={"Brand logo"}
              alt="Assist Along"
            ></img>
          </div>
        }
      />
      <div
        className={
          "main-contain flex justify-center items-start  transition-all duration-500 h-screen"
        }
      >
        <div className="max-w-full w-full">
          <div
            className="cart-product-wrap relative min-h-screen pb-28"
            style={{ backgroundColor: "#F5FAF5" }}
          >
            <div className="max-w-[632px] mx-auto px-4">
              <div className="">
                <p className="font-bold font-mediumAvenir uppercase text-gray-700 text-xs md:pt-9 pt-5 mb-5 md:mb-7 md:text-gray-variant md:uppercase md:text-sm md:tracking-wide md:font-black md:font-blackAvenir">
                  Reorder Your Favorite products from any of Our brands
                </p>
              </div>
              <div className="products-wrap">
                {brands.map((item) => {
                  return (
                    <div
                      className="bg-white p-4 mb-6 md:mb-10 product-block productCsBox rounded-md"
                      //   style={cardStyle}
                    >
                      <div className="flex mt-4 items-start md:pl-7">
                        <div className="md:w-[55%] w-[40%] max-w-[232px]">
                          {item.image_url && (
                            <img
                              className="rounded-lg object-contain max-h-[94px]"
                              src={item.image_url}
                              alt={item.brand_name}
                            ></img>
                          )}
                        </div>
                        <div className="text-right ml-auto pl-1 md:w-[55%] w-[60%]">
                          <p className="font-mediumAvenir text-base md:text-sm font-medium AvenirMediumtext mb-7">
                            {item.brand_name}
                          </p>
                          <div className="md:w-[92px] md:h-[48px] w-[78px] h-[32px] font-medium text-right inline-flex">
                            <button
                              className={`btn text-xs`}
                              onClick={() => navigate(`/${item.brand_slug}`)}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <Brands />
                <Brands />
                <Brands /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandIndex;
