import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { subscribe, unsubscribe } from "./api/allowListApi";

import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";

const UnsubscribePage = () => {
  const location = useLocation();
  const [resubbed, setResubbed] = useState(false);

  const { siteConfiguration } = useContext(SiteConfigurationContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("address")) {
      unsubscribe(searchParams.get("address") as string);
    }
  }, [location]);

  const resubscribe = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("address")) {
      subscribe(searchParams.get("address") as string).then(() =>
        setResubbed(true)
      );
    }
  };

  return (
    <>
      <div className="bg-gray3 lg:pt-14 pt-0 absolute w-full top-0 lg:bg-bg_grey">
        <div className="container bg-white mx-auto max-w-3xl">
          <div className="md:p-11 p-6 md:pb-36">
            <div className="container max-w-sm mx-auto">
              <div className="logo-wrap pb-6">
                <img
                  className="w-28 mx-auto"
                  alt="brand-logo"
                  src={siteConfiguration.image_url}
                ></img>
              </div>
              {resubbed ? (
                <div className="text-center border border-designerGray border-opacity-10 sm:p-8 p-4 rounded-lg mb-5">
                  <h2 className="text-designerGray text-opacity-90 mb-2 text-base font-blackAvenir">
                    You’ve resubscribed! Welcome back.
                  </h2>
                </div>
              ) : (
                <div className="text-center border border-designerGray border-opacity-10 sm:p-8 p-4 rounded-lg mb-5">
                  <h2 className="text-designerGray text-opacity-90 mb-2 text-base font-blackAvenir">
                    You’ve been unsubscribed
                  </h2>
                  <p className="text-11 text-designerGray leading-5 font-body">
                    We won’t send any more communications - it may take up to
                    24hrs.
                  </p>
                  <p className="text-11 text-designerGray leading-5 font-body">
                    Unsubscribe by mistake?{" "}
                    <button
                      className="text-blue underline"
                      onClick={resubscribe}
                    >
                      Re-subscribe
                    </button>{" "}
                  </p>
                </div>
              )}
              <div className="text-center w-full mb-12">
                <p className="flex items-center justify-center text-designerGray text-opacity-75 font-semibold tracking-wide text-8 mx-auto">
                  Powered by{" "}
                  <img
                    className="ml-2 h-4"
                    alt="brand-logo"
                    src="/assistalong 2.svg"
                  ></img>
                </p>
              </div>
              <div className="mx-4 pb-8 border-b border-designerGray border-opacity-10 mb-8">
                <h2 className="text-13 mb-2 text-designerGray text-opacity-90 font-bold font-blackAvenir">
                  What is AssistAlong?
                </h2>
                <p className="text-11 text-designerGray text-opacity-90 leading-5 font-body">
                  We help your customers with an incredibly easy way to re-order
                  your products, turning every sale into a recurring revenue
                  stream.
                  <a
                    className="text-blue underline block"
                    href="https://assistalong.com"
                  >
                    {" "}
                    Learn more about AssistAlong
                  </a>
                </p>
              </div>
              <div className="text-center">
                <div className="logo-wrap pb-3">
                  <img
                    className="w-20 mx-auto"
                    alt="brand-logo"
                    src={siteConfiguration.image_url}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { UnsubscribePage };
