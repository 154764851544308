/* eslint-disable react-hooks/rules-of-hooks */
import { Address } from "../pages/checkout/model/CheckoutModel";
import * as yup from "yup";
import {
  Cart,
  Product,
  ProductVariants,
} from "../pages/product/models/ProductModel";
import { Frequency } from "../pages/editReminder/model/FrequencyModel";

export const formatNumber = (num: number) =>
  (Math.round(num * 100) / 100).toFixed(2);

export const oneLinerAddress = (address: Address) => {
  return `${address.address1} ${address.address2} ${address.city}, ${address.province_code} ${address.zip}`;
};

export const onInputFocus = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  setFields([...fields, name]);
};

export const onInputBlur = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  const tempFields = [...fields];
  if (tempFields.indexOf(name) > -1) {
    setFields([...tempFields.filter((field) => field !== name)]);
  }
};

export const getClass = (
  fieldName: any,
  values: any,
  focusedFields: string[]
) => {
  const classes = [];
  if (focusedFields.includes(fieldName)) {
    classes.push("focused ");
  }
  if (values && values[fieldName]) {
    classes.push("has-value");
  }
  return classes.join(" ");
};

export const getAddressString = (address: Address) => {
  const { address1, address2, city, province_code } = address;
  return [address1, address2, city, province_code]
    .filter((item) => item)
    .join(", ");
};

export const getZipString = (address: Address) => {
  const { zip, country_code } = address;
  return [zip, country_code].filter((item) => item).join(", ");
};

export const validateValues = (
  values: any,
  schema: yup.AnyObjectSchema,
  setErrors: (errors: any) => void
) => {
  const tempErrors: any = {};
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e: any) {
    e.inner.forEach((item: yup.ValidationError) => {
      if (item.path) {
        tempErrors[item.path] = item.message;
      }
    });
  }
  setErrors(tempErrors);
  return Object.keys(tempErrors).length > 0;
};

export const basicPhoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

export const daysToSeconds = (days: number) => days * 86400;
export const weeksToSeconds = (weeks: number) => weeks * 604800;

export const frequencyToSeconds = (freq: Frequency) => {
  if (freq.quality === "week") {
    return weeksToSeconds(freq.quantity);
  } else {
    return daysToSeconds(freq.quantity);
  }
};

export const getShippingDescription = (title: string) => {
  if (title === "Standard") {
    return "(3 to 4 business days)";
  } else if (title === "Express") {
    return "(1 to 2 business days)";
  }
  if (title === "Economy") {
    return "(5 to 8 business days)";
  }
};

export const changeCartQuantity = (
  currentProductVariants: ProductVariants,
  shopifyCartConfig: Cart,
  setShopifyCartConfig: (cart: Cart) => void
) => {
  const tempFormattedCart = { ...shopifyCartConfig };
  tempFormattedCart.product.variants.forEach((mainVariant: ProductVariants) => {
    if (mainVariant.id === currentProductVariants.id) {
      mainVariant.quantity = currentProductVariants.quantity;
    }
  });
  tempFormattedCart.collection.forEach((subProduct: Product) => {
    subProduct.variants.forEach((subVariant) => {
      if (subVariant.id === currentProductVariants.id) {
        subVariant.quantity = currentProductVariants.quantity;
      }
    });
  });
  setShopifyCartConfig(tempFormattedCart);
};
