export const Loading = () => (
  <div className="flex min-h-screen justify-center items-center">
    <div
      style={{ borderTopColor: "transparent" }}
      className="w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"
    ></div>
  </div>
);

export const FormLoader = () => (
  <div
    style={{ borderTopColor: "transparent" }}
    className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"
  />
);
