import { useState } from "react";
import Select from "react-select";
import { Frequency } from "../model/FrequencyModel";
import { OptionType } from "../../../components/OptionTypeSelector";

export const DelayReminderSelector = ({
  updateFrequency,
  placeholder,
  isFullWidth,
}: {
  updateFrequency: (freq: Frequency) => void;
  placeholder: string;
  isFullWidth?: boolean;
}) => {
  var quantityOptions: OptionType[] = [];

  for (var i = 1; i <= 30; i++) {
    quantityOptions.push({ label: String(i), value: String(i) });
  }

  const qualityOptions: OptionType[] = [
    { value: "day", label: "day(s)" },
    { value: "week", label: "week(s)" },
  ];

  const [frequency, setFrequency] = useState<Frequency>({
    quantity: 1,
    quality: "day",
  });

  const handleQuantityChange = (newQuantity: number) => {
    setFrequency({ ...frequency, quantity: newQuantity });
    updateFrequency({ ...frequency, quantity: newQuantity });
  };

  const handleQualityChange = (newQuality: "day" | "week") => {
    setFrequency({ ...frequency, quality: newQuality });
    updateFrequency({ ...frequency, quality: newQuality });
  };

  return (
    <div
      className={`dropdown-wrap sm:w-48 sm:mt-0 mt-1 flex ${
        isFullWidth && "md:w-full"
      }`}
    >
      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #808080",
          }),
        }}
        isSearchable={false}
        className="rounded w-3/4 border-0.5 border-borderGrey-greyBorder text-xs font-mediumAvenir text-bold border-opacity-50"
        classNamePrefix="dp"
        options={quantityOptions}
        placeholder="After"
        defaultValue={quantityOptions[0]}
        onChange={(e: any) => handleQuantityChange(e.value)}
      />

      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #808080",
          }),
        }}
        isSearchable={false}
        className="ml-4 rounded w-full border-0.5 border-borderGrey-greyBorder text-xs font-mediumAvenir text-bold border-opacity-50"
        classNamePrefix="dp"
        options={qualityOptions}
        placeholder="After"
        defaultValue={qualityOptions[0]}
        onChange={(e: any) => handleQualityChange(e.value)}
      />
    </div>
  );
};
