import { Route, Routes } from "react-router-dom";
import { AuthPage } from "../pages/auth/AuthPage";
import { RequiresAuth } from "./RequiresAuth";
import { EditReminderPage } from "../pages/editReminder/EditReminderPage";
import { ErrorPage } from "../pages/error/ErrorsPage";
import { OrderHistoryPage } from "../pages/orderHistory/OrderHistoryPage";
import { OrderPage } from "../pages/order/OrderPage";
import { ProductPage } from "../pages/product/ProductPage";
import { ProfilePage } from "../pages/profile/ProfilePage";
import { PurchaseHistoryPage } from "../pages/purchaseHistory/PurchaseHistoryPage";
import { PurchasedProductsPage } from "../pages/purchaseProducts/PurchsedProductsPage";
import { SuccessPage } from "../pages/success/SuccessPage";
import { UnsubscribePage } from "../pages/unsubscribe/UnsubscribePage";
import { ContextProvider } from "./ContextProvider";
import BrandRedirect from "../BrandRedirect";
import BrandIndex from "../pages/brands/BrandIndex";
import SingleBrand from "../pages/brands/SingleBrand";
import SmartCheckoutRedirect from "../SmartCheckoutRedirect";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ContextProvider />}>
        <Route path="/buy/:uuid" element={<ProductPage />} />
        <Route path="/buy/:uuid/:cuuid" element={<ProductPage />} />
        <Route path="/reminder/:uuid" element={<EditReminderPage />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/" element={<RequiresAuth component={ProfilePage} />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/profile"
          element={<RequiresAuth component={ProfilePage} />}
        />
        <Route
          path="/history"
          element={<RequiresAuth component={PurchaseHistoryPage} />}
        />
        <Route
          path="/products"
          element={<RequiresAuth component={PurchasedProductsPage} />}
        />
        <Route
          path="/order/:uuid"
          element={<RequiresAuth component={OrderPage} />}
        />
        <Route
          path="/myOrders/:uuid"
          element={<RequiresAuth component={OrderHistoryPage} />}
        />
        <Route path="/brands" element={<BrandIndex />} />
        <Route path="/:brandName" element={<SingleBrand />} />
      </Route>
      <Route path="/:brandName/:uuid" element={<BrandRedirect />} />
      <Route path="/:brandName/*" element={<SmartCheckoutRedirect />} />
      {/* <Route
        exact
        path="/:brandName/:cuuid/:smartCheckoutName"
        element={<SmartCheckoutRedirect />}
      />
      <Route
        exact
        path="/:brandName/:cuuid/:smartCheckoutName/:productId"
        element={<SmartCheckoutRedirect />}
      /> */}
    </Routes>
  );
};

export default AppRoutes;
