import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const KEY = "home";

export function useSaveHomepage(save: boolean) {
  const [home, setHome] = useState(localStorage.getItem(KEY));

  const location = useLocation();

  useEffect(() => {
    if (save && home !== location.pathname) {
      setHome(location.pathname);
      localStorage.setItem(KEY, location.pathname);
    }
  }, [location.pathname, save, home]);

  return {
    home,
  };
}
