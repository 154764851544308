/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import Lottie from "lottie-react";
import animation from "../auth/login-success-animation.json";

export const SuccessPage = () => {
  const [redirect, setRedirect] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnTo = searchParams.get("returnTo");
  const uuid = searchParams.get("reminder");

  let url = `${returnTo}`;

  if (uuid && uuid !== "undefined") {
    url = `${url}&reminder=${uuid}`;
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      setRedirect(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  if (redirect) {
    return <Navigate to={url} />;
  }

  return (
    <div className="flex justify-center items-center">
      <Lottie animationData={animation} />
    </div>
  );
};
