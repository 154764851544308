// import { useAuth0 } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect } from "react";
import { useGetCustomerUser } from "../api/customerUserApi";
import { CustomerUserResponse } from "../models/CustomerUserModel";

export const CustomerUserContext = createContext<{
  customerUser?: CustomerUserResponse | null;
  loading: boolean;
  error: any | null;
  refresh: () => void;
}>({
  loading: false,
  error: null,
  refresh: () => {},
});

export const CustomerUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, error, refresh, data: customerUser } = useGetCustomerUser();
  const { logout, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (!loading && error && error.error && error.error.request) {
      // logout user if api returns 401 error while fetching the customer user
      const errorCode = error.error.request.status;
      if (errorCode === 401) {
        // logout customer
        logout({ returnTo: window.location.origin + "/profile" });
      }
    }
    // eslint-disable-next-line
  }, [loading, error, customerUser]);

  useEffect(() => {
    if (customerUser) {
      const params = new URLSearchParams(window.location.search);
      if (params.has("phone_number")) {
        if (
          params.get("phone_number")?.replace("+", "").trim() !==
            customerUser.phone.replace("+", "").trim() &&
          isAuthenticated
        ) {
          logout({ returnTo: window.location.origin + "/profile" });
        }
        window.history.pushState({}, document.title, "/profile");
      }
    }
    // eslint-disable-next-line
  }, [customerUser]);

  return (
    <CustomerUserContext.Provider
      value={{
        customerUser,
        refresh,
        loading,
        error,
      }}
    >
      {children}
    </CustomerUserContext.Provider>
  );
};
