import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Auth0ProviderWithHistory from "./pages/auth/Auth0ProviderWithHistory";

ReactDOM.render(
  <Auth0ProviderWithHistory>
    <App />
  </Auth0ProviderWithHistory>,
  document.getElementById("root")
);
