import React, { createContext, useState } from "react";
import { AxiosResponse } from "axios";
import { CartLineItem, CartV2 } from "../pages/checkout/model/CartModel";
import { Cart } from "../pages/product/models/ProductModel";
import { ProductPageConfiguration } from "../models/ConfigurationModel";
import {
  calculateCartV2,
  purchaseCartV2,
} from "../pages/product/api/CartsV2Requests";
import {
  Address,
  Calculation,
  CartCalculationResponse,
  CartResponse,
  Checkout,
} from "../pages/checkout/model/CheckoutModel";
import { SAVED_PRODUCTS_KEY } from "../App";

export type CartContextType = {
  savedLineItems: CartLineItem[];
  clearCart: () => void;
  shopifyCartConfig: any;
  setShopifyCartConfig: (cart: Cart) => void;
  cart: CartV2;
  discountCode: string | undefined;
  setDiscountCode: (code: string | undefined) => void;
  updateCart: (item: CartLineItem) => void;
  itemsInCart: () => number;
  calculate: (
    lineItems: CartLineItem[],
    address?: Address,
    uuid?: number
  ) => Promise<AxiosResponse<CartCalculationResponse>>;
  purchase: (
    config: ProductPageConfiguration,
    phone: string,
    lineItems: CartLineItem[],
    shipping: Address,
    billing: Address,
    accessToken: string,
    discountCode?: string
  ) => Promise<AxiosResponse<CartResponse>>;
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  setCartLineItems: (lineItems: CartLineItem[]) => void;
};

export const CartContext = createContext<CartContextType>({
  savedLineItems: [],
  clearCart: () => localStorage.removeItem(SAVED_PRODUCTS_KEY),
  shopifyCartConfig: {},
  setShopifyCartConfig: () => "",
  cart: { lineItems: [] },
  updateCart: () => "",
  itemsInCart: () => 0,
  dirty: false,
  discountCode: undefined,
  setDiscountCode: (_x) => null,
  setDirty: (_x) => false,
  setCartLineItems: (_li) => null,
  calculate: (lineItems: CartLineItem[], address?: Address, uuid?: number) => {
    const co: Calculation = {
      lineItems: lineItems.map(({ quantity, variant }) => {
        return { quantity, variantId: variant.variant_id };
      }),
      shippingAddress: address,
      productPageConfigurationId: uuid,
    };
    return calculateCartV2(co);
  },

  purchase: (
    config: ProductPageConfiguration,
    phone: string,
    lineItems: CartLineItem[],
    shipping: Address,
    billing: Address,
    accessToken: string,
    discountCode?: string
  ) => {
    const mapped = lineItems.map((p) => ({
      variantId: p.variant.variant_id,
      quantity: p.quantity,
    }));

    const co: Checkout = {
      billingAddress: billing,
      shippingAddress: shipping,
      lineItems: mapped,
      phone: phone,
      discountCode,
    };

    return purchaseCartV2(
      {
        ...co,
        productPageConfigurationId: String(config.id),
      },
      accessToken
    );
  },
});

export const CartContextProvider: ({
  children,
}: {
  children: React.ReactNode;
}) => JSX.Element = ({ children }) => {
  const savedProducts = localStorage.getItem(SAVED_PRODUCTS_KEY);

  const [savedLineItems] = useState<CartLineItem[]>(
    (savedProducts && JSON.parse(savedProducts)) || []
  );

  const [shopifyCartConfig, setShopifyCartConfig] = useState<Cart>();
  const [cart, setCart] = useState<CartV2>({ lineItems: [] });
  const [discountCode, setDiscountCode] = useState<string | undefined>();

  const [dirty, setDirty] = useState(false);

  const updateCart = (lineItem: CartLineItem) => {
    const li = cart?.lineItems?.filter(
      ({ variant }) => variant?.variant_id !== lineItem?.variant?.variant_id
    );

    if (lineItem.quantity < 1) {
      setCart({ lineItems: li });
      localStorage.setItem(SAVED_PRODUCTS_KEY, JSON.stringify(li));
    } else {
      const found = cart.lineItems.findIndex(
        ({ variant }) => variant.variant_id === lineItem.variant.variant_id
      );

      if (found !== -1) {
        var tmp = [...cart.lineItems];

        tmp.splice(found, 1, lineItem);

        setCart({ lineItems: tmp });

        localStorage.setItem(SAVED_PRODUCTS_KEY, JSON.stringify(tmp));
      } else {
        setCart({ lineItems: [...li, lineItem] });

        localStorage.setItem(
          SAVED_PRODUCTS_KEY,
          JSON.stringify([...li, lineItem])
        );
      }
    }
    setDirty(true);
  };

  const setCartLineItems = (lineItems: CartLineItem[]) => {
    setCart({ lineItems });

    localStorage.setItem(SAVED_PRODUCTS_KEY, JSON.stringify(lineItems));
  };

  const itemsInCart = () =>
    cart.lineItems.reduce((prev, curr) => prev + curr.quantity, 0);

  const clearCart = () => {
    setDirty(true);
    localStorage.removeItem(SAVED_PRODUCTS_KEY);
  };

  const calculate = (
    lineItems: CartLineItem[],
    address?: Address,
    uuid?: string
  ) => {
    const co: Calculation = {
      lineItems: lineItems?.map(({ quantity, variant }) => {
        return { quantity, variantId: variant?.variant_id };
      }),
      shippingAddress: address,
      discountCode,
      productPageConfigurationId: uuid,
    };

    // console.log(co, "co---------");
    setDirty(false);
    return calculateCartV2(co);
  };

  const purchase = (
    config: ProductPageConfiguration,
    phone: string,
    lineItems: CartLineItem[],
    shipping: Address,
    billing: Address,
    accessToken: string,
    discountCode?: string
  ) => {
    const mapped = lineItems.map((p) => ({
      variantId: p.variant.variant_id,
      quantity: p.quantity,
    }));

    const co: Checkout = {
      billingAddress: billing,
      shippingAddress: shipping,
      lineItems: mapped,
      phone: phone,
      discountCode,
    };

    return purchaseCartV2(
      {
        ...co,
        productPageConfigurationId: String(config.id),
      },
      accessToken
    );
  };

  const handleDiscountChange = (code: string | undefined) => {
    if (code) {
      setDiscountCode(code);
      setDirty(true);
    }
  };

  return (
    <CartContext.Provider
      value={{
        calculate,
        savedLineItems,
        clearCart,
        shopifyCartConfig,
        setShopifyCartConfig,
        cart,
        updateCart,
        itemsInCart,
        purchase,
        dirty,
        setDirty,
        discountCode,
        setDiscountCode: handleDiscountChange,
        setCartLineItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
