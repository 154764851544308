import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";

import { PersonalInformation } from "../checkout/model/CheckoutModel";
import {
  CustomerUserAddress,
  CustomerUserAddressRequest,
  CustomerUserResponse,
  UpdateCustomerUserAddressRequest,
} from "../../models/CustomerUserModel";
import { addAddress, updateAddress } from "../../api/customerUserApi";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";
import { updateCustomerUser as patchCustomerUser } from "../../api/customerUserApi";

import { Loading } from "../../components/Loading";
import { PersonalInformationFields } from "./components/PersonalInformationFields";
import { ProfileTabs } from "./components/ProfileTabs";
import { DangerAlert } from "./components/Alert";
import {
  AddNewShippingAddressLine,
  ShippingAddressLine,
} from "./components/ShippingAddressLine";

import { validateValues } from "../../util/helpers";

export const ProfilePage = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { customerUser, loading, refresh } = useContext(CustomerUserContext);

  const [userInfo, setUserInfo] = useState<CustomerUserResponse>();
  const [submitted] = useState<Boolean>(false);
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState<string>();

  const [addresses, setAddresses] = useState<CustomerUserAddress[]>([]);

  let schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required(),
  });

  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    if (customerUser && !loading) {
      setUserInfo(customerUser);
      setAddresses(customerUser.shipping_addresses);
    }
  }, [customerUser, loading]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (submitted) {
      validateInputs();
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const validateInputs = async () =>
    await validateValues({ ...userInfo }, schema, setErrors);

  const saveShippingAddress = async (
    req: CustomerUserAddressRequest,
    cu: CustomerUserResponse
  ) => {
    const accessToken = await getAccessTokenSilently();
    const response = await addAddress(cu.phone, req, accessToken);
    if (response.data) {
      setAddresses([response.data, ...addresses]);
      return true;
    }
    return false;
  };

  const updateShippingAddress = async (
    req: UpdateCustomerUserAddressRequest,
    cu: CustomerUserResponse,
    index: number
  ) => {
    const accessToken = await getAccessTokenSilently();
    const { data } = await updateAddress(cu.phone, req, accessToken);

    const updated = [...addresses];

    if (data) {
      updated.splice(index, 1, { ...data });
      setAddresses(updated);
      return true;
    }

    return false;
  };

  const updateCustomer = async (cu: CustomerUserResponse) => {
    await validateInputs();
    setError(undefined);
    getAccessTokenSilently()
      .then((accessToken) =>
        patchCustomerUser(
          cu.phone,
          {
            email: cu.email,
            name: cu.name,
          },
          accessToken
        )
      )
      .then(() => setChanged(false))
      .catch((error) => {
        if (error.response) {
          setError(JSON.stringify(error.response.data));
        }
      });
  };

  if (userInfo) {
    return (
      <div className="min-h-screen bg-gray-custom py-4 absolute top-0 w-full pl-4 pr-4">
        <>
          <div className="profile-wrap">
            <div className="max-w-xl container mx-auto">
              <ProfileTabs activeTab="profile" />
              <div>
                {!loading ? (
                  <PersonalInformationFields
                    errors={errors}
                    phoneDisabled={true}
                    initial={userInfo}
                    onPersonalInformationChange={(i: PersonalInformation) => {
                      setUserInfo({ ...userInfo, ...i });
                      setChanged(true);
                    }}
                  />
                ) : (
                  <Loading />
                )}
                <button
                  disabled={!changed}
                  onClick={() => updateCustomer(userInfo)}
                  className={`btn h-12 md:mt-4 mt-8 btn-save ${
                    !changed &&
                    "cursor-not-allowed disabled:opacity-50 bg-blue-light border-none font-bold tracking-04"
                  } `}
                >
                  Save Changes
                </button>
                {error && <DangerAlert errorMessage={error} />}
                <div className="py-6 mt-4">
                  <h3 className="mb-3 font-bold font-mediumAvenir text-gray-600 tracking-wide text-xs">
                    SHIPPING ADDRESSES
                  </h3>
                  <div
                    className={`flex flex-col relative bg-white rounded shipping-field`}
                  >
                    {addresses.map((addr, index) => (
                      <ShippingAddressLine
                        key={addr.id}
                        addresses={addresses}
                        address={addr}
                        name={userInfo.name}
                        onSaveUpdate={(e) =>
                          updateShippingAddress(e, userInfo, index)
                        }
                      />
                    ))}
                    <AddNewShippingAddressLine
                      onSave={(e) => saveShippingAddress(e, userInfo)}
                      addresses={addresses}
                      refresh={refresh}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-4 absolute top-0 w-full pl-4 pr-4">
      <Loading />
    </div>
  );
};
