import axios from "axios";
import {
  Calculation,
  Checkout,
  CartCalculationResponse,
  CartCheckoutResponse,
  CartResponse,
} from "../../checkout/model/CheckoutModel";

const API_URL = process.env.REACT_APP_API_URL;
const calculateEndpoint = `/carts/calculate`;
const checkoutEndpoint = `/carts/checkout`;

export const calculateCart = (id: string, calc: Calculation) =>
  axios.post<CartCalculationResponse>(
    `${API_URL}/cart_configurations/${id}/${calculateEndpoint}`,
    { cart: calc }
  );

export const purchaseCart = (id: string, co: Checkout) =>
  axios.post<CartCheckoutResponse>(
    `${API_URL}/cart_configurations/${id}/${checkoutEndpoint}`,
    { cart: co }
  );

export const getCart = (configId: string, cartId: string) =>
  axios.get<CartResponse>(
    `${API_URL}/cart_configurations/${configId}/carts/${cartId}`
  );

export const getCartV2 = (cartId: string) =>
  axios.get<CartResponse>(`${API_URL}/carts_v2/${cartId}`);

export const purchaseCartV2 = (
  cartId: string,
  co: Checkout,
  accessToken: string
) =>
  axios.post<CartCheckoutResponse>(
    `${API_URL}/carts_v2/${cartId}/purchase`,
    {
      cart: co,
    },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );

export const calculateCartV2 = (cartId: string, co: Checkout) =>
  axios.post<CartCalculationResponse>(
    `${API_URL}/carts_v2/${cartId}/calculate`,
    { cart: co }
  );

export const updateCartV2 = (cartId: string, co: Checkout) =>
  axios.put<CartCheckoutResponse>(`${API_URL}/carts_v2/${cartId}/purchase`, {
    cart: co,
  });

export const checkoutV2 = (
  businessId: string,
  co: Checkout,
  accessToken: string
) =>
  axios.post<CartCheckoutResponse>(
    `${API_URL}/businesses/${businessId}/carts_v2/checkout`,
    { cart: co },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
