import React from "react";

const NavBar2 = ({
  left,
  middle,
  right,
}: {
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
}) => {
  return (
    <>
      <div className={`relative max-w-7xl mx-auto`}>
        <div className="w-full flex items-center relative">
          {left}
          {middle}
        </div>
        {right}
      </div>
    </>
  );
};

export default NavBar2;
