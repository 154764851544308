import { useContext, useEffect } from "react";
import { getCartV2 } from "../../order/api/cartApi";
import {
  ProductPageConfiguration,
  ProductVariant,
} from "../../../models/ConfigurationModel";
import { CartContext } from "../../../contexts/CartContext";
import { useLocation } from "react-router-dom";

export function useBuildCart(
  config?: ProductPageConfiguration,
  addDefaultProduct?: boolean
) {
  const { updateCart, itemsInCart, savedLineItems, setCartLineItems } =
    useContext(CartContext);

  const location = useLocation();

  useEffect(() => {
    const buildFromCartParams = (c: ProductPageConfiguration) => {
      const searchParams = new URLSearchParams(location.search);
      const cartId = searchParams.get("cartId");
      if (cartId) {
        getCartV2(cartId).then(({ data }) => {
          const collectionVariants: ProductVariant[] =
            c.upsell_products.flatMap((el) => el.variants);

          const allVariants: ProductVariant[] = [
            ...c.product.variants,
            ...collectionVariants,
          ];

          data.line_items.forEach(({ quantity, variant_id }) => {
            const variant = allVariants.find(
              (el) => el.variant_id === variant_id
            );

            if (variant) {
              updateCart({ variant, quantity });
            }
          });
        });
      }
    };

    const buildFromVariantParams = (c: ProductPageConfiguration) => {
      const searchParams = new URLSearchParams(location.search);

      const collectionVariants: ProductVariant[] = c.upsell_products.flatMap(
        (el) => el.variants
      );

      const allVariants: ProductVariant[] = [
        ...c.product.variants,
        ...collectionVariants,
      ];

      const selectedVariant = allVariants.find(
        ({ variant_id }) => variant_id === searchParams.get("variant")
      );
      const selectedQuantity = Number(searchParams.get("quantity") || 1);

      if (selectedVariant) {
        setCartLineItems([
          { variant: selectedVariant, quantity: selectedQuantity },
        ]);
      }
    };

    const buildCart = (c: ProductPageConfiguration) => {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.has("cartId")) {
        buildFromCartParams(c);
      } else if (searchParams.has("variant")) {
        buildFromVariantParams(c);
      } else {
        updateCart({ variant: c.product.variants[0], quantity: 1 });
      }
    };

    const searchParams = new URLSearchParams(location.search);

    if (config && searchParams.has("variant")) {
      buildFromVariantParams(config);
    } else if (config && searchParams.has("cartId")) {
      buildFromCartParams(config);
    } else if (savedLineItems.length > 0 && itemsInCart() === 0) {
      setCartLineItems(savedLineItems);
    } else if (config && itemsInCart() === 0 && addDefaultProduct) {
      buildCart(config);
    }

    //eslint-disable-next-line
  }, [config]);
}
