import React, { useEffect, useState } from "react";
// import {
//   getSiteConfiguration,
//   RevisionType,
// } from "../api/ConfigurationRequests";
import {
  useLocation,
  //  useParams
} from "react-router-dom";
// const { REACT_APP_BUSINESS_ID }: any = process.env;

export interface SiteConfiguration {
  primary_color: string;
  secondary_color: string;
  image_url: string;
  brand_name: string;
  product_page_configuration_id?: number;
}

export const DefaultSiteConfiguration: SiteConfiguration = {
  primary_color: "#085C3F",
  secondary_color: "#F5FAF5",
  image_url: "",
  brand_name: "",
};

export const SiteConfigurationContext = React.createContext<{
  siteConfiguration: SiteConfiguration;
  setSiteConfiguration: (sc: SiteConfiguration) => void;
}>({
  siteConfiguration: DefaultSiteConfiguration,
  setSiteConfiguration: (_sc) => null,
});

export const SiteConfigProvider: ({
  children,
}: {
  children: React.ReactNode;
}) => JSX.Element = ({ children }) => {
  const [siteConfig, setSiteConfig] = useState(DefaultSiteConfiguration);
  const location = useLocation();
  // const params = useParams();
  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(location.search);

      // const revision = searchParams.get("revision");
      const primaryColor = searchParams.get("primaryColor");
      const secondaryColor = searchParams.get("secondaryColor");
      const imageUrl = searchParams.get("imageUrl");
      const preview = searchParams.get("preview");

      if (preview) {
        setSiteConfig({
          ...siteConfig,
          primary_color: primaryColor
            ? `${primaryColor}`
            : siteConfig.primary_color,
          secondary_color: secondaryColor
            ? `${secondaryColor}`
            : siteConfig.secondary_color,
          image_url: imageUrl || siteConfig.image_url,
        });
      } else {
        // const revisionType: RevisionType =
        //   revision === null ? "deployed" : "working";
        // let reminderUUID = "";
        // if (location.pathname.includes("/reminder/") && params.uuid) {
        //   reminderUUID = params.uuid;
        // }
        // getSiteConfiguration(
        //   REACT_APP_BUSINESS_ID,
        //   revisionType,
        //   reminderUUID
        // ).then(({ data }) => setSiteConfig(data));
      }
    })();
    //eslint-disable-next-line
  }, [location.search]);

  return (
    <SiteConfigurationContext.Provider
      value={{
        siteConfiguration: siteConfig,
        setSiteConfiguration: setSiteConfig,
      }}
    >
      {children}
    </SiteConfigurationContext.Provider>
  );
};
