import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const audience: string = process.env.REACT_APP_API_AUDIENCE || "";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/auth`}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
