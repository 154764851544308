import { OrderResponse } from "../../../models/CustomerUserModel";
import { useNavigate } from "react-router-dom";

export const HistoryProductBox = ({
  product: { alt_text, order_name, image_url, created_at, uuid },
  isLast,
}: {
  product: OrderResponse;
  isLast: Boolean;
}) => {
  const todaysDate = new Date();
  const todayStart = todaysDate.setHours(0, 0, 0, 0);
  const dObj = new Date();
  const yesterdayStart = new Date(dObj.setDate(dObj.getDate() - 1)).setHours(
    0,
    0,
    0,
    0
  );

  const navigate = useNavigate();

  const onClickOrder = () => {
    if (uuid) {
      navigate(`/myOrders/${uuid}`);
    }
  };

  const getDateString = () => {
    if (created_at) {
      const purchasedDate = new Date(created_at);
      if (todaysDate.getFullYear() === purchasedDate.getFullYear()) {
        if (purchasedDate.getTime() > todayStart) {
          return "Today";
        } else if (purchasedDate.getTime() > yesterdayStart) {
          return "Yesterday";
        }
      }
      return `${purchasedDate.toLocaleString("default", {
        month: "short",
      })} ${purchasedDate.getDate()}`;
    }
    return "";
  };
  return (
    <div
      className={`single-row p-4 flex relative ${
        !isLast && "border-b border-designerGray border-opacity-10"
      } cursor-pointer`}
      onClick={onClickOrder}
    >
      <div className="img-wrap">
        <img src={image_url} alt={alt_text} className="h-10 w-10" />
      </div>
      <div className="product-content-wrap pl-3 flex flex-col">
        <h3 className="text-designerGray tracking-wide text-base font-bold font-mediumAvenir mb-1">
          {`Order ${order_name}`}
        </h3>
        <span className="text-designerGray text-opacity-75 text-xs tracking-wide">
          Purchased {getDateString()}
        </span>
      </div>
      <div className="right-arrow absolute right-4 top-1/2 transform -translate-y-2/4">
        <img src="right-angle.svg" alt="right-angle" />
      </div>
    </div>
  );
};
