import { useContext } from "react";
import { useNavigate } from "react-router";
import NavBar2 from "../../components/Navbar2";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";
import { orderData } from "../../dummyData/orderData";
import { SummaryView } from "./components/OrderSummaryView";

export const OrderPage = () => {
  const { customerUser } = useContext(CustomerUserContext);
  const navigate = useNavigate();
  const onClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50 py-4 absolute top-0 w-full pl-4 pr-4">
        <div>
          <div className="max-w-xl container mx-auto">
            <NavBar2
              left={
                <div className="back-btn mr-4 left-0 absolute top-[50%] translate-y-[-50%]">
                  <span className="cursor-pointer" onClick={() => onClick()}>
                    <img src="\back-arrow.svg" alt=""></img>
                  </span>
                </div>
              }
              middle={
                <div className="flex px-[36px] w-full py-4 sm:justify-end justify-between h-16">
                  <div className="absolute top-[50%] translate-y-[-50%] left-[50%] text-extrabold translate-x-[-50%] sm:left-[50%] sm:translate-x-[-50%]">
                    Order #CD-091384
                  </div>
                </div>
              }
            />
            <SummaryView
              order={orderData}
              customerUser={customerUser || undefined}
              // showSummary={true}
            />
            <div className="fixed bottom-0 left-0 right-0 shadow-drawer_shadow p-3 bg-white md:hidden block z-10">
              <div
                className={`overlay bg-black bg-opacity-70 h-full fixed top-0 left-0  transition-all ease-in-out duration-500 opacity-0 z-mz`}
              ></div>
              <div className="flex justify-between items-center gap-4 relative max-w-960 mx-auto">
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H5C5.35747 0.25 5.66526 0.502289 5.73541 0.852807L6.61551 5.25H23C23.2235 5.25 23.4354 5.3497 23.5779 5.52192C23.7204 5.69414 23.7786 5.92093 23.7367 6.1405L22.1357 14.5361C22.0099 15.1692 21.6655 15.7378 21.1628 16.1425C20.662 16.5456 20.0359 16.7605 19.3934 16.75H9.68664C9.04405 16.7605 8.41799 16.5456 7.91725 16.1425C7.41473 15.738 7.07043 15.1697 6.94455 14.537L5.27255 6.18328C5.26672 6.16005 5.26197 6.13639 5.25836 6.11235L4.38524 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM6.91573 6.75L8.41563 14.2439C8.47278 14.5316 8.62932 14.7901 8.85785 14.9741C9.08637 15.158 9.37232 15.2558 9.66563 15.2501L9.68 15.25H19.4L19.4144 15.2501C19.7077 15.2558 19.9936 15.158 20.2222 14.9741C20.45 14.7906 20.6063 14.5331 20.6639 14.2463L20.6644 14.2439L22.0935 6.75H6.91573ZM7.25003 21C7.25003 20.0335 8.03353 19.25 9.00003 19.25C9.96653 19.25 10.75 20.0335 10.75 21C10.75 21.9665 9.96653 22.75 9.00003 22.75C8.03353 22.75 7.25003 21.9665 7.25003 21ZM18.25 21C18.25 20.0335 19.0335 19.25 20 19.25C20.9665 19.25 21.75 20.0335 21.75 21C21.75 21.9665 20.9665 22.75 20 22.75C19.0335 22.75 18.25 21.9665 18.25 21Z"
                    fill="#2F363D"
                  />
                </svg>
                <div className="bg-designerOrange absolute left-4 top-1 h-5 w-5 rounded-full flex items-center justify-center">
                  <p className="text-white text-sm">{1}</p>
                </div>
                <div className="text-left flex-1">
                  <p className="font-bold font-mediumAvenir">${10}</p>
                  <p className="text-sm">with tax & shipping</p>
                </div>
                <button
                  className="py-1 px-4 rounded-lg text-white block h-10"
                  style={{ backgroundColor: "#085C3F" }}
                >
                  Buy again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
