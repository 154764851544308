import Select, { SingleValue } from "react-select";
import { SelectValueContainer } from "../components/SelectValueContainer";

export interface OptionType {
  value: string;
  label: string;
}

export const OptionTypeSelector = ({
  options,
  currentOption,
  setReminder,
  placeholder,
  isFullWidth,
}: {
  options: OptionType[];
  currentOption: string;
  setReminder: (value: string) => void;
  placeholder: string;
  isFullWidth?: boolean;
}) => {
  return (
    <div
      className={`dropdown-wrap  sm:w-40 sm:mt-0 ${
        isFullWidth && "md:w-full w-full"
      }`}
    >
      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #808080",
          }),
        }}
        isSearchable={false}
        className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
        classNamePrefix="dp"
        options={options}
        components={{ ValueContainer: SelectValueContainer }}
        placeholder={placeholder}
        value={options.find((item) => item.value === currentOption)}
        onChange={(e: SingleValue<OptionType>) => e && setReminder(e?.value)}
      />
    </div>
  );
};
