import { components, ValueContainerProps } from "react-select";

const { ValueContainer } = components;

export const SelectValueContainer = ({
  children,
  ...props
}: ValueContainerProps | any) => {
  return (
    <ValueContainer
      {...props}
      className="value-container text-xs h-12 text-designerGray text-opacity-50 tracking-wide font-bold font-mediumAvenir flex flex-col items-start justify-center relative z-0"
    >
      {props.hasValue && (
        <span
          className={`text-xxs ${
            props.hasValue &&
            "has-value whitespace-pre text-designerGray text-opacity-50 font-bold font-mediumAvenir tracking-wide"
          }`}
        >
          SEND REMINDER
        </span>
      )}
      {children}
    </ValueContainer>
  );
};
