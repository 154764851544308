import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { getBrandProducts } from "../../api/ConfigurationRequests";
import { Loading } from "../../components/Loading";
import NavBar2 from "../../components/Navbar2";
import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";
import { BrandProducts, Product } from "../../models/ConfigurationModel";
import BrandProductReminder from "./components/BrandProductReminder";

const SingleBrand = () => {
  const navigate = useNavigate();
  const { siteConfiguration, setSiteConfiguration } = useContext(
    SiteConfigurationContext
  );
  const { brandName } = useParams<{ brandName: string }>();

  const [products, setProducts] = useState<Product[]>([]);
  const [config, setConfig] = useState<BrandProducts>();
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  const getProducts = () => {
    if (brandName) {
      setIsLoading(true);
      getBrandProducts(brandName)
        .then((res) => {
          setIsLoading(false);
          if (res.data.products) {
            setConfig(res.data);
            setSiteConfiguration({
              primary_color: res.data.primary_color,
              secondary_color: res.data.secondary_color,
              image_url: res.data.image_url,
              brand_name: res.data.brand_name,
            });
            setProducts(res.data.products);
          } else {
            setError("products not found");
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError("products not found");
        });
    }
  };

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (error) {
    return (
      <div className="flex min-h-screen w-full flex-col justify-center items-center px-4 xl:px-0">
        <div className="">
          <div className="text-2xl md:!text-6xl font-semibold mb-6 xl:!mb-14">
            404 - Products not found
          </div>
          <div className="">
            <button
              className="cursor-pointer text-sm rounded py-2 px-4 bg-blue hover:bg-blue-800 text-white font-medium"
              onClick={() => navigate("/")}
            >
              Homepage
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <NavBar2
        middle={
          <div className="flex relative pl-4 w-full py-4 bg-white sm:justify-end justify-between h-16">
            {siteConfiguration?.image_url && (
              <img
                className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-12"
                src={siteConfiguration.image_url}
                key={siteConfiguration.image_url}
                alt="Brand banner"
              ></img>
            )}
          </div>
        }
      />
      <div
        className={
          "main-contain flex justify-center items-start  transition-all duration-500 h-screen"
        }
      >
        <div className="max-w-full w-full">
          <div
            className="cart-product-wrap relative min-h-screen pb-28"
            style={{ backgroundColor: siteConfiguration.secondary_color }}
          >
            <div className="max-w-[632px] mx-auto px-4">
              <div className="flex justify-between md:pt-9 pt-5 mb-5 md:mb-7 ">
                <div>
                  <p className="font-bold font-mediumAvenir uppercase text-gray-700 text-xs md:text-gray-variant md:uppercase md:text-sm md:tracking-wide md:font-black md:font-blackAvenir">
                    Place your order with a Few clicks
                  </p>
                </div>
                <div>
                  <p className="font-bold font-mediumAvenir uppercase text-xs  md:uppercase md:text-sm md:tracking-wide md:font-black md:font-blackAvenir">
                    <Link to="/brands" className="text-blue-700">
                      Back
                    </Link>
                  </p>
                </div>
              </div>
              <div className="products-wrap">
                {products.length > 0 ? (
                  config &&
                  products.map((product: any, index) => (
                    <BrandProductReminder
                      key={index}
                      product={product}
                      color={siteConfiguration.primary_color}
                      purchaseUrl={config.shopify_domain}
                    />
                  ))
                ) : (
                  <div className="text-center">
                    <span>No Products Found</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBrand;
