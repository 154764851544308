export const orderData = {
  uuid: "a7bccf16-e8bb-4dad-8b34-fb4f3abd3e92",
  order_name: "aaaaaaa",
  created_at: "2022-04-01T14:01:57.666Z",
  total_price: "11",
  total_tax: "1",
  subtotal_price: "12",
  discount_amount: "1",
  discount_code: "sss",
  total_shipping_price: "1",
  shipping_address: {
    address1: "address1",
    address2: "address2",
    city: "city",
    zip: "zip",
    country_code: "country_code",
    province_code: "province_code",
  },
  line_items: [
    {
      quantity: 11,
      product: {
        id: 1,
        variant_id: "dada",
        name: "name",
        variant_name: "var,ant_name",
        image_url:
          "https://cdn.shopify.com/s/files/1/0600/2825/6437/products/gentleCleanser.png?v=1632347172",
        price: "12",
        alt_text: "12",
        available: true,
        selected_options: [{ name: "aaa", value: "aa" }],
        repurchase_url: "aaaa",

        reminder_frequency: {
          id: 1,
          default_step_id: 1,
          default_step_time_seconds: "adadad",
          steps: [
            {
              id: 1,
              step_time_seconds: 1,
              frequency: { quantity: 1, quality: "day" },
            },
          ],
          pause_step: {
            step_time_seconds: 1,
            label: "label",
          },
        },
        existing_reminder: {
          to: "Adad",
          uuid: "Adada",
          period_seconds: 1212,
          created_at: "adad",
          updated_at: "adad",

          delay_start_seconds: 1,
          product_page_configuration_id: 1,
          communication_method: "sms",
          paused: true,
        },
      },
    },
    {
      quantity: 11,
      product: {
        id: 1,
        variant_id: "dada",
        name: "name",
        variant_name: "var,ant_name",
        image_url:
          "https://cdn.shopify.com/s/files/1/0600/2825/6437/products/gentleCleanser.png?v=1632347172",
        price: "12",
        alt_text: "12",
        available: true,
        selected_options: [{ name: "aaa", value: "aa" }],
        repurchase_url: "aaaa",
        reminder_frequency: {
          id: 1,
          default_step_id: 1,
          default_step_time_seconds: "adadad",
          steps: [
            {
              id: 1,
              step_time_seconds: 1,
              frequency: { quantity: 1, quality: "day" },
            },
          ],
          pause_step: {
            step_time_seconds: 1,
            label: "label",
          },
        },
        existing_reminder: {
          to: "Adad",
          uuid: "Adada",
          period_seconds: 1212,
          created_at: "adad",
          updated_at: "adad",

          delay_start_seconds: 1,
          product_page_configuration_id: 1,
          communication_method: "sms",
          paused: true,
        },
      },
    },
  ],
};
