import { Outlet } from "react-router";
import { CustomerUserProvider } from "../contexts/CustomerUserContext";
import { CartContextProvider } from "../contexts/CartContext";
import { SiteConfigProvider } from "../contexts/SiteConfigurationContext";
const ContextProvider = () => {
  return (
    <div className={`main`}>
      <CustomerUserProvider>
        <CartContextProvider>
          <SiteConfigProvider>
            <Outlet />
          </SiteConfigProvider>
        </CartContextProvider>
      </CustomerUserProvider>
    </div>
  );
};

export { ContextProvider };
