import { decamelizeKeys } from "humps";
import ahoy from "ahoy.js";

export default function setupAxios(axios: any, accessToken?: string) {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers.Accept = "application/json";

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      config.headers = {
        ...config.headers,
        "Ahoy-Visit": ahoy.getVisitId(),
        "Ahoy-Visitor": ahoy.getVisitorId(),
      };

      if (config.data) {
        config.data = decamelizeKeys(config.data);
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}
