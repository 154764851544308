import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { CartResponse } from "../checkout/model/CheckoutModel";
import { ProductPageConfiguration } from "../../models/ConfigurationModel";
import { getProductPageConfig } from "../../api/ConfigurationRequests";
import { saveProduct } from "../../api/customerUserApi";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";

import { CartContext } from "../../contexts/CartContext";
import { SiteConfigurationContext } from "../../contexts/SiteConfigurationContext";

import { Loading } from "../../components/Loading";
import { CartView } from "./components/CartView";
import { CartPrice } from "./components/CartPrice";
import { ProductCardV2 } from "./components/ProductCardV2";
import { useBuildCart } from "./components/use-build-cart";
import { useTrackPageVisit } from "./components/use-track-view";
import { useSaveHomepage } from "./components/use-save-homepage";
import NavBar2 from "../../components/Navbar2";
import { CartLineItem } from "../checkout/model/CartModel";

export const ProductPage = () => {
  const [config, setConfig] = useState<ProductPageConfiguration>();
  const [completed, setCompleted] = useState(false);
  const [cartId, setCartId] = useState<string>();
  const [initialCalculated, setInitialCalculated] = useState<boolean>(false);
  const [cartPrice, setCartPrice] = useState("0.00");
  const [discounts, setDiscounts] = useState<{ [key: string]: string }>({});
  const [fetching, setFetching] = useState(false);
  const { customerUser, refresh, loading } = useContext(CustomerUserContext);

  // change for
  const [buyLaterProductIds, setBuyLaterProductIds] = useState<string[]>([]);

  const { siteConfiguration } = useContext(SiteConfigurationContext);
  const navigate = useNavigate();
  const {
    cart,
    calculate,
    setDirty,
    itemsInCart,
    clearCart,
    setCartLineItems,
  } = useContext(CartContext);

  const location = useLocation();

  const { variant, uuid } = useParams<{ variant: string; uuid: string }>();

  const { isLoading } = useAuth0();

  if (variant && cart.lineItems.length > 0) {
    clearCart();
  }

  const onSubmit = (c: CartResponse) => {
    setCompleted(true);
    setCartId(c.uuid);
  };

  useSaveHomepage(true);

  useMemo(() => {
    if (uuid) {
      getProductPageConfig(uuid)
        .then(({ data }) => {
          let productFilter;
          let upsellFilter;
          if (data?.product.variants?.length > 1) {
            productFilter = data.product.variants.filter(
              (obj) => obj.variant_name !== "Default Title"
            );
            data.product.variants = productFilter;
          }

          if (data?.upsell_products?.length > 0) {
            upsellFilter = data?.upsell_products?.filter((obj) =>
              obj.variants.length > 1
                ? obj?.variants?.filter(
                    (childObj) => childObj.variant_name !== "Default Title"
                  )
                : obj?.variants
            );
            data.upsell_products = upsellFilter;
          }

          setConfig(data);
        })
        .catch((err) => {
          console.log("Err: ", err);
        });
    }
  }, [uuid]);

  useEffect(() => {
    if (!loading && customerUser) {
      setDirty(true);
    }
  }, [loading, customerUser, setDirty]);

  useEffect(() => {
    setDirty(true);
  }, [setDirty]);

  useBuildCart(config, true);

  const calculateCartPrice = (initial = false) => {
    if (!loading && itemsInCart() > 0) {
      setFetching(true);
      calculate(
        cart.lineItems,
        customerUser?.default_shipping_address?.address,
        uuid ? +uuid : 0
      )
        .then(({ data }) => {
          setFetching(false);
          const { calculation, org_discount_val } = data;
          if (org_discount_val) {
            let codes: { [key: string]: string } = {};
            org_discount_val.forEach((item) => {
              if (item.discounted_price > 0) {
                codes[item.product_id] = item.discounted_price.toFixed(2);
              }
            });
            setDiscounts(codes);
          }
          // setCartPrice(calculation.totalPrice || "0.00");
          setCartPrice(calculation.subtotalPrice || "0.00");
          setInitialCalculated(true);
        })
        .catch(() => {
          setFetching(false);
        });
    } else if (itemsInCart() < 1) {
      setCartPrice("0.00");
    }
  };

  useEffect(() => {
    calculateCartPrice(true);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (initialCalculated) {
      calculateCartPrice();
    }
    // eslint-disable-next-line
  }, [location, cart, config]);

  useEffect(() => {
    if (!config?.product) {
      return;
    }

    let data = cart?.lineItems?.filter(
      (obj: CartLineItem) =>
        obj?.variant?.name === config?.product?.title ||
        config?.upsell_products?.find(
          (childObj) => childObj?.title === obj?.variant?.name
        )
    );

    if (!data?.length) {
      data.push({ variant: config.product.variants[0], quantity: 1 });
    }

    setCartLineItems(data);
    // eslint-disable-next-line
  }, [config]);

  useTrackPageVisit();

  if (!config || !cart || isLoading) return <Loading />;

  if (completed && cartId && config) {
    return <Navigate to={`/order/${cartId}`} replace />;
  }

  const buyLaterProductHandle = (productId: string, variantId?: string) => {
    if (variantId) {
      if (customerUser) {
        saveProduct(customerUser.phone, variantId);
      }
      setBuyLaterProductIds([...buyLaterProductIds, productId]);
    }
  };

  return (
    <>
      <NavBar2
        middle={
          <div className="flex relative pl-4 w-full py-4 bg-white sm:justify-end justify-between h-16">
            {siteConfiguration?.image_url && (
              <img
                className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8"
                src={siteConfiguration?.image_url}
                alt="Brand banner"
              ></img>
            )}
          </div>
        }
        right={
          <span className="py-2 right-4 absolute top-[50%] translate-y-[-50%]">
            <button
              onClick={() => {
                navigate("/profile?shopify=true");
              }}
            >
              <img className="md:h-8 h-7" src="/assistalong_a.svg" alt="icon" />
            </button>
          </span>
        }
      />
      <div
        className={
          "main-contain flex justify-center items-start md:mt-8 mt-10 transition-all duration-500 h-screen"
        }
      >
        <div className="max-w-full w-full">
          <div
            className="cart-product-wrap relative min-h-screen pb-28"
            style={{ backgroundColor: siteConfiguration.secondary_color }}
          >
            <div className="bg-white absolute h-36 top-0 w-full"></div>
            <div className="max-w-7xl mx-auto flex flex-row md:pr-4 pr-0">
              <div className="lg:w-3/4 md:w-4/6 w-full h-auto">
                {config && (
                  <div className="px-4 -mb-16 md:-mb-28 relative z-10">
                    <div className="max-w-960 mx-auto">
                      <ProductCardV2
                        isTop={true}
                        product={config.product}
                        main={true}
                        color={siteConfiguration.primary_color}
                        buyLaterProductIds={buyLaterProductIds}
                        buyLaterProductHandle={buyLaterProductHandle}
                        user={customerUser || null}
                        discounts={discounts}
                        calculating={fetching}
                      />
                    </div>
                  </div>
                )}

                {config && (
                  <div className="w-full px-4 md:pt-36 pt-20 mt-10">
                    <p className="font-bold font-mediumAvenir text-gray-700 text-sm mb-3 md:mb-4 md:text-gray-variant md:uppercase md:text-xs md:tracking-wide md:font-black md:font-blackAvenir">
                      {config.upsell_text}
                    </p>
                    <div className="regimen-cards-wrap lg:grid lg:gap-x-4 lg:grid-cols-2">
                      {config.upsell_products.map((product) => (
                        <div key={product.id} className="mb-2">
                          <ProductCardV2
                            product={product}
                            main={true}
                            color={siteConfiguration.primary_color}
                            buyLaterProductIds={buyLaterProductIds}
                            buyLaterProductHandle={buyLaterProductHandle}
                            user={customerUser || null}
                            discounts={discounts}
                            calculating={fetching}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <CartView
                discounts={discounts}
                cu={customerUser || null}
                totalCartItems={itemsInCart()}
                cartPrice={cartPrice}
                config={config}
                setCartPrice={setCartPrice}
                updateCustomerUser={refresh}
              />
            </div>
          </div>

          <CartPrice
            discounts={discounts}
            onSubmit={onSubmit}
            buttonColor={siteConfiguration.primary_color}
            configId={`${config.id}`}
            cartPrice={cartPrice}
            totalCartItems={itemsInCart()}
          />
        </div>
      </div>
    </>
  );
};
