import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getPurchasedProducts,
  // getSavedProducts,
} from "../../api/customerUserApi";
import { ProductVariant } from "../../models/ConfigurationModel";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";

import { Loading } from "../../components/Loading";
import { ProfileTabs } from "../profile/components/ProfileTabs";
import { PurchasedProductsList } from "./components/PurchasedProductsList";

export const PurchasedProductsPage = () => {
  const [products, setProducts] = useState<ProductVariant[]>([]);

  const { user, getAccessTokenSilently } = useAuth0();
  const { customerUser } = useContext(CustomerUserContext);

  const [loading, setLoading] = useState<Boolean>(true);

  const getProducts = async (phone: string) => {
    const accessToken = await getAccessTokenSilently();
    const res = await getPurchasedProducts(phone, accessToken);
    if (res?.data?.products) {
      return res.data.products;
    }
    return [];
  };

  // const getSaved = async (phone: string) => {
  //   const accessToken = await getAccessTokenSilently();
  //   const res = await getSavedProducts(phone, accessToken);
  //   if (res?.data?.products) {
  //     return res.data.products;
  //   }
  //   return [];
  // };

  useEffect(() => {
    if (customerUser) {
      const getData = async () => {
        const allProducts = await getProducts(customerUser.phone);
        // const savedProducts = await getSaved(customerUser.phone);

        // const savedIds = new Set(savedProducts.map((x) => x.variant_id));
        // const diff = allProducts.filter((x) => !savedIds.has(x.variant_id));

        // setProducts([...diff, ...savedProducts]);
        setProducts(allProducts);
        setLoading(false);
      };
      getData();
    }

    //eslint-disable-next-line
  }, [customerUser]);

  return (
    <>
      <div className="min-h-screen bg-gray-50 py-4 absolute top-0 w-full pl-4 pr-4">
        <div className="max-w-xl container mx-auto">
          <ProfileTabs activeTab="products" />
          {!user || loading ? (
            <Loading />
          ) : (
            <>
              <div className="mt-2">
                {customerUser && (
                  <PurchasedProductsList
                    products={products}
                    phone={customerUser.phone}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
