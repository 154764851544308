import { Product, ProductVariant } from "../models/ConfigurationModel";
import pluralize from "pluralize";
import { OptionType } from "../components/OptionTypeSelector";

export function frequencySelectOptions(product: Product | ProductVariant) {
  const steps = product.reminder_frequency.steps;

  const options: OptionType[] = steps.map(
    ({ frequency, step_time_seconds }) => {
      const { quantity, quality } = frequency;
      return {
        label: `Every ${quantity} ${pluralize(quality, quantity)}`,
        value: String(step_time_seconds),
      };
    }
  );

  return options;
}
