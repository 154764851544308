import { OrderResponse } from "../../../models/CustomerUserModel";
import { HistoryProductBox } from "../../purchaseProducts/components/HistoryProductBox";

export const PurchaseHistoryList = ({
  products,
}: {
  products: OrderResponse[];
}) => {
  return (
    <>
      {products.map((product, index) => (
        <HistoryProductBox
          key={index}
          product={product}
          isLast={products.length - 1 === index}
        />
      ))}
    </>
  );
};
