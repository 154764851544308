import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";

export const AuthPage = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && user !== undefined && !isLoading) {
      // console.log({ isAuthenticated, user, isLoading })
      setTimeout(() => {
        navigate(window.location.pathname);
      }, 500);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user, isLoading]);
  useEffect(() => {
    const locationHash = location.hash.replace("#", "");
    const params = new URLSearchParams(locationHash);
    if (params.has("error") && params.get("error") === "unauthorized") {
      navigate("/profile");
    }
    // eslint-disable-next-line
  }, []);

  return <Loading />;
};
