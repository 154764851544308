import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import NavBar2 from "../../../components/Navbar2";

export const ProfileTabs = ({ activeTab }: { activeTab: string }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <div>
      {/* {/ <div className="hidden md:block"> /} */}
      <div className="tab-section pb-5">
        <NavBar2
          middle={
            <div className="flex px-[36px] relative w-full py-4  sm:justify-end justify-between h-16">
              <img
                className="object-contain absolute top-[50%] translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] h-8 left-[50%] translate-x-[-50%]"
                src="\assistalong 2.svg"
                alt=""
              ></img>
            </div>
          }
          right={
            <span className="py-2 flex right-0 translate-y-[-50%] absolute top-[50%]">
              <button
                className="w-5 h-5"
                onClick={() => {
                  logout({ returnTo: window.location.origin + "/profile" });
                }}
              >
                <img
                  className="w-[19px] h-[18px]"
                  src="/logout.svg"
                  alt="icon"
                />
              </button>
            </span>
          }
        />
        <div className="tab-container-wrap mt-4 md:mt-6">
          <div className="tabs-wrap">
            <div className="tab-title">
              <ul className="flex border-b border-black border-opacity-50">
                <li
                  className="mr-8 font-mediumAvenir"
                  onClick={() => navigate("/profile", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "profile" ? "tab-active" : "tab"
                    }`}
                  >
                    Profile
                  </span>
                </li>
                <li
                  className="mr-8 font-mediumAvenir"
                  onClick={() => navigate("/products", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "products" ? "tab-active" : "tab "
                    }`}
                  >
                    My Products
                  </span>
                </li>
                <li
                  className="mr-8 font-mediumAvenir"
                  onClick={() => navigate("/history", { replace: true })}
                >
                  <span
                    className={`text-xxs md:text-sm ${
                      activeTab === "history" ? "tab-active" : "tab "
                    }`}
                  >
                    Purchase History
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
