import axios from "axios";
import {
  BrandConfiguration,
  BrandProducts,
  ProductPageConfiguration,
} from "../models/ConfigurationModel";
import { SiteConfiguration } from "../contexts/SiteConfigurationContext";

const API_URL = process.env.REACT_APP_API_URL;

export type RevisionType = "working" | "deployed";

export const getProductPageConfig = (
  uuid: string,
  revision_type: RevisionType = "deployed"
) =>
  axios.get<ProductPageConfiguration>(
    `${API_URL}/product_page_configurations/${uuid}?revision=${revision_type}`
  );

export const getSiteConfiguration = (
  businessId: string,
  revision_type: RevisionType = "deployed",
  reminderUUID?: string
) => {
  if (reminderUUID) {
    return axios.get<SiteConfiguration>(
      `${API_URL}/businesses/${businessId}/site_configurations?revision=${revision_type}&reminder_uuid=${reminderUUID}`
    );
  } else {
    return axios.get<SiteConfiguration>(
      `${API_URL}/businesses/${businessId}/site_configurations?revision=${revision_type}`
    );
  }
};

export const getAllBrands = () => {
  return axios.get<{ brands: BrandConfiguration[] }>(
    `${API_URL}/site_configurations/all/brands?revision=working`
  );
};

export const getBrandProducts = (brandName: string) => {
  return axios.get<BrandProducts>(
    `${API_URL}/site_configurations/${brandName}/products`
  );
};
