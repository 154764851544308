import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getOrderHistory } from "../../api/customerUserApi";
import { OrderResponse } from "../../models/CustomerUserModel";
import { CustomerUserContext } from "../../contexts/CustomerUserContext";

import { PurchaseHistoryList } from "./components/PurchaseHistoryList";
import { Loading } from "../../components/Loading";
import { ProfileTabs } from "../profile/components/ProfileTabs";

export const PurchaseHistoryPage = () => {
  const [productCurrent, setProductCurrent] = useState<OrderResponse[]>([]);
  const [productLastThree, setProductLastThree] = useState<OrderResponse[]>([]);

  const { user, getAccessTokenSilently } = useAuth0();
  const { customerUser } = useContext(CustomerUserContext);

  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    const getProductsCurrent = async (phone: string) => {
      const from = new Date();
      from.setMonth(from.getMonth() - 1);

      const accessToken = await getAccessTokenSilently();
      const response = await getOrderHistory(phone, accessToken, from);

      if (response?.data?.orders) {
        setProductCurrent(response.data.orders);
      }
    };

    const getProductsLastThree = async (phone: string) => {
      const from = new Date();
      const to = new Date();

      from.setMonth(new Date().getMonth() - 4);
      to.setMonth(new Date().getMonth() - 1);

      const accessToken = await getAccessTokenSilently();
      const response = await getOrderHistory(phone, accessToken, from, to);

      if (response?.data?.orders) {
        setProductLastThree(response.data.orders);
      }
    };

    if (customerUser) {
      const getData = async () => {
        await getProductsCurrent(customerUser.phone);
        await getProductsLastThree(customerUser.phone);
        setLoading(false);
      };
      getData();
    }
  }, [customerUser, getAccessTokenSilently]);

  return (
    <div className="min-h-screen bg-gray-50 py-4 absolute top-0 w-full pl-4 pr-4">
      <div className="max-w-xl container mx-auto">
        <ProfileTabs activeTab="history" />
        {!user || loading ? (
          <Loading />
        ) : (
          <>
            <div className="history-content-wrap mt-1">
              <div className="month-history-box">
                <h3 className="mb-5 uppercase font-bold font-mediumAvenir text-gray-variant tracking-wide text-xs">
                  This Month
                </h3>
                <div className="box-wrap bg-white rounded-md mb-2">
                  <PurchaseHistoryList products={productCurrent} />
                </div>
              </div>
              <div className="month-history-box pt-4">
                <h3 className="mb-5 uppercase font-bold font-mediumAvenir text-gray-variant tracking-wide text-xs">
                  Last 3 Months
                </h3>
                <div className="box-wrap bg-white rounded-md mb-4">
                  <PurchaseHistoryList products={productLastThree} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
