import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
// import { Outlet } from "react-router";

const RequiresAuth = ({
  component,
}: {
  component: React.ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, {
    returnTo: "/products",
  });

  return <Component />;
};

export { RequiresAuth };
