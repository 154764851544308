import React, { useEffect } from "react";
export const ProductSavePopUp = ({
  alert,
  setAlert,
}: {
  alert: Boolean;
  setAlert: (alert: Boolean) => void;
}) => {
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [alert]);

  return (
    <span
      className={`fixed bottom-32 mx-auto bg-gray-800 bg-opacity-75	text-white text-center rounded-lg inline-block py-2 px-10 w-auto left-0 right-0 max-w-max transition-all ease-in-out duration-2000 ${
        alert ? "opacity-1 visible " : "opacity-0 invisible "
      } `}
    >
      Product Saved
    </span>
  );
};
