import { useState } from "react";
import { PersonalInformation } from "../../checkout/model/CheckoutModel";
import { getClass, onInputBlur, onInputFocus } from "../../../util/helpers";

export const PersonalInformationFields = ({
  initial,
  onPersonalInformationChange,
  phoneDisabled = false,
  enablePhoneInput = true,
  errors = {},
}: {
  initial?: PersonalInformation;
  onPersonalInformationChange: (i: PersonalInformation) => void;
  phoneDisabled?: boolean;
  enablePhoneInput?: boolean;
  errors?: any;
}) => {
  const [focusedFields, setFocusedFields] = useState<string[]>([]);
  return (
    <div className="flex flex-col">
      {enablePhoneInput && (
        <>
          <div
            className={`field-wrap phone-field mt-2 ${getClass(
              "phone",
              initial,
              focusedFields
            )}`}
          >
            <label className="input-label">Phone Number</label>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50003 1.62508C5.9254 1.62508 5.3743 1.85335 4.96797 2.25968C4.56164 2.66601 4.33337 3.21711 4.33337 3.79175V5.41675H8.6667V3.79175C8.6667 3.21711 8.43843 2.66601 8.0321 2.25968C7.62577 1.85335 7.07467 1.62508 6.50003 1.62508ZM9.75003 5.41675V3.79175C9.75003 2.92979 9.40762 2.10314 8.79813 1.49365C8.18864 0.884158 7.36199 0.541748 6.50003 0.541748C5.63808 0.541748 4.81143 0.884158 4.20194 1.49365C3.59244 2.10314 3.25003 2.92979 3.25003 3.79175V5.41675H2.70837C1.81091 5.41675 1.08337 6.14428 1.08337 7.04175V10.8334C1.08337 11.7309 1.81091 12.4584 2.70837 12.4584H10.2917C11.1892 12.4584 11.9167 11.7309 11.9167 10.8334V7.04175C11.9167 6.14428 11.1892 5.41675 10.2917 5.41675H9.75003ZM2.70837 6.50008C2.40922 6.50008 2.16671 6.74259 2.16671 7.04175V10.8334C2.16671 11.1326 2.40922 11.3751 2.70837 11.3751H10.2917C10.5909 11.3751 10.8334 11.1326 10.8334 10.8334V7.04175C10.8334 6.74259 10.5909 6.50008 10.2917 6.50008H2.70837Z"
                fill="#1D1D1B"
                stroke="black"
                strokeWidth="0.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              required
              type="tel"
              id="phone"
              autoComplete="off"
              className={`input-field ${phoneDisabled && "text-gray-400"}`}
              value={initial?.phone}
              onChange={(e) =>
                onPersonalInformationChange({ phone: e.target.value })
              }
              onFocus={() =>
                onInputFocus(focusedFields, setFocusedFields, "phone")
              }
              onBlur={() =>
                onInputBlur(focusedFields, setFocusedFields, "phone")
              }
              disabled={phoneDisabled}
            />
          </div>
          <p className="text-danger text-xs">{errors["phone"]}</p>
        </>
      )}
      <div
        className={`field-wrap mt-2 ${getClass(
          "name",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Full Name</label>
        <input
          required
          type="text"
          id="fullname"
          autoComplete="off"
          className="input-field"
          placeholder=""
          value={initial?.name || ""}
          onFocus={() => onInputFocus(focusedFields, setFocusedFields, "name")}
          onBlur={() => onInputBlur(focusedFields, setFocusedFields, "name")}
          onChange={(e) =>
            onPersonalInformationChange({ name: e.target.value })
          }
        />
      </div>
      <p className="text-danger text-xs">{errors["name"]}</p>

      <div
        className={`field-wrap mt-2 ${getClass(
          "email",
          initial,
          focusedFields
        )}`}
      >
        <label className="input-label">Email</label>
        <input
          required
          type="text"
          id="email"
          autoComplete="off"
          className="input-field"
          placeholder=""
          inputMode="email"
          value={initial?.email || ""}
          onChange={(e) =>
            onPersonalInformationChange({ email: e.target.value })
          }
          onFocus={() => onInputFocus(focusedFields, setFocusedFields, "email")}
          onBlur={() => onInputBlur(focusedFields, setFocusedFields, "email")}
        />
      </div>
      <p className="text-danger text-xs">{errors["email"]}</p>
    </div>
  );
};
