import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const ENDPOINT = "allow_lists";

export const subscribe = (to: string) =>
  axios.post(`${API_URL}/${ENDPOINT}`, { allow: { to } });

export const unsubscribe = (to: string) =>
  axios.delete(`${API_URL}/${ENDPOINT}/${to}`);
