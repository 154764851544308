import { ProductVariant } from "../../../models/ConfigurationModel";
import { ProductBox } from "./ProductBox";

const ProductBoxLine = ({
  product,
  index,
  phone,
}: {
  product: ProductVariant;
  index: number;
  phone: string;
}) => {
  return (
    <ProductBox product={product} key={index} index={index} phone={phone} />
  );
};

export const PurchasedProductsList = ({
  products,
  phone,
}: {
  phone: string;
  products: ProductVariant[];
}) => {
  return (
    <>
      {products.map((product, index) => (
        <ProductBoxLine
          key={index}
          product={product}
          index={index}
          phone={phone}
        />
      ))}
    </>
  );
};
