import { CustomerUserResponse } from "../../../models/CustomerUserModel";
import {
  formatNumber,
  getAddressString,
  getZipString,
} from "../../../util/helpers";
import { LineItem } from "../model/OrderModel";

export const SummaryView = ({
  customerUser,
  order,
}: {
  customerUser?: CustomerUserResponse;
  order: any;
  // order: OrderResponse;
}) => {
  return (
    <div>
      <div className={`py-5 opacity-1 visible transition-all duration-1000`}>
        <div className="flex items-center justify-between mb-[15px]">
          <h3 className="text-[#1D1D1B] text-[12px] font-normal">Items</h3>
          <p className="text-[#1D1D1B] text-[12px] font-normal">$325.00</p>
        </div>
        <div className="">
          {order.line_items.map((item: LineItem, index: number) => (
            <div key={index}>
              <div className="order-item mb-[15px] flex items-start justify-between">
                <div className="leftD flex items-center">
                  <img
                    className="w-[32px] h-[32px] p-1 object-contain object-center border border-[#E7E7E7] rounded-[6px] mr-2"
                    src={item.product.image_url}
                    alt={item.product.alt_text ?? item.product.name}
                  ></img>
                  <div className="productDT w-[calc(100%_-_32px)]">
                    <h3 className="text-[#333333] text-[12px] font-extrabold flex items-center">
                      Crème de la Mer{" "}
                      <span className="count w-[16px] h-[16px] rounded-[50%] bg-[#041023] text-[#FAFAFA] flex items-center justify-center ml-[6px]">
                        2
                      </span>
                    </h3>
                    <p className="text-[#727984] font-normal text-[12px]">
                      5oz
                    </p>
                  </div>
                </div>
                <div className="price text-[#333333] text-[12px] font-normal">
                  $175
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="summary-list mb-9">
          <ul>
            <li className="text-designerGray text-xs mb-2 flex justify-between tracking-wide font-normal">
              Shipping & Handling{" "}
              <span>${formatNumber(Number(order.total_shipping_price))}</span>
            </li>
            <li className="text-designerGray text-xs mb-2 flex justify-between tracking-wide font-normal">
              Taxes <span>${formatNumber(Number(order.total_tax))}</span>
            </li>
            <li className="flex justify-between tracking-wide">
              <strong className="text-designerGray font-bold font-mediumAvenir text-sm">
                Order Total
              </strong>
              <strong className="font-bold font-mediumAvenir">
                ${formatNumber(Number(order.total_price))}
              </strong>
            </li>
          </ul>
        </div>
        <div className="shipping-add-block">
          <h3 className="text-xs text-designerGray tracking-wide mb-2">
            <strong className="font-bold font-mediumAvenir">
              {customerUser?.name}
            </strong>
          </h3>
          <p className="uppercase text-designerGray text-opacity-70 text-xs tracking-wide font-normal">
            {getAddressString(order.shipping_address)}
          </p>
          <p className="uppercase text-designerGray text-opacity-70 text-xs tracking-wide font-normal">
            {getZipString(order.shipping_address)}
          </p>
        </div>
        <div>
          <button className="bg-[#085C3F] mt-[70px] hidden sm:block w-full text-[#FFFFFF] text-[14px] font-medium py-[10px] px-[15px]">
            Buy Again
          </button>
        </div>
      </div>
    </div>
  );
};
