import { ChangeEvent, memo, useContext, useEffect, useState } from "react";
import { CartLineItem } from "../../checkout/api/CartModel";
import { Product, ProductVariant } from "../../../models/ConfigurationModel";
import { CartContext } from "../../../contexts/CartContext";
import { ProductSavePopUp } from "./ProductSavePopUp";
import pluralize from "pluralize";
import {
  OptionType,
  OptionTypeSelector,
} from "../../../components/OptionTypeSelector";
import { CustomerUserResponse } from "../../../models/CustomerUserModel";
import { RepeatReminderResponse } from "../../editReminder/model/ReminderModel";

export const ReminderSelector = ({
  product,
  onChange,
  existingReminder,
  isFullWidth,
}: {
  product: Product | ProductVariant;
  onChange: (value: string) => void;
  existingReminder?: RepeatReminderResponse;
  isFullWidth?: boolean;
}) => {
  const steps = product.reminder_frequency.steps;
  const pauseStep = product.reminder_frequency.pause_step;
  const defaultOption = product.reminder_frequency.default_step_time_seconds;

  const pauseOption = {
    label: pauseStep.label,
    value: String(pauseStep.step_time_seconds),
  };
  const options: OptionType[] = [pauseOption];

  const existingOption = existingReminder?.paused
    ? -1
    : existingReminder?.period_seconds;

  const [selected, setSelected] = useState(
    String(existingOption || defaultOption)
  );

  const stepOptions: OptionType[] = steps.map(
    ({ frequency, step_time_seconds }) => {
      const { quantity, quality } = frequency;
      return {
        label: `Every ${quantity} ${pluralize(quality, quantity)}`,
        value: String(step_time_seconds),
      };
    }
  );

  const onSelected = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <OptionTypeSelector
      options={[...options, ...stepOptions]}
      currentOption={selected}
      placeholder="Set Reminder"
      isFullWidth={isFullWidth || false}
      setReminder={onSelected}
    />
  );
};

export const ProductCardV2 = memo(
  ({
    product,
    main,
    color,
    buyLaterProductIds,
    buyLaterProductHandle,
    isTop,
    user,
    discounts,
    calculating,
  }: {
    product: Product;
    main: boolean;
    color: string;
    buyLaterProductIds: string[];
    buyLaterProductHandle: (productId: string, variantId?: string) => void;
    isTop?: boolean;
    user: CustomerUserResponse | null;
    discounts?: { [key: string]: string };
    calculating: boolean;
  }) => {
    const [alert, setAlert] = useState<Boolean>(false);
    const [price, setPrice] = useState<string>(product?.variants?.[0]?.price);
    const qtyPickerOptions: any[] = [];
    const [cardStyle, setCardStyle] = useState({});

    const { cart, updateCart } = useContext(CartContext);

    for (let i: number = 0; i < 20; i++) {
      qtyPickerOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    const [selectedVariant, setSelectedVariant] = useState<CartLineItem>({
      variant: product.variants[0],
      quantity: 0,
    });
    const [oldQ, setOldQ] = useState(selectedVariant.quantity);

    const [selectedOption, setSelectedOption] = useState(() => {
      // let matched = null;
      // if (isTop) {
      const productVariants = product.variants.map((item) => item.variant_id);
      const matched = cart?.lineItems?.find((item) =>
        productVariants.includes(item?.variant?.variant_id)
      );
      // }
      const defaultSelection: any = {};
      if (matched) {
        matched.variant.selected_options.forEach((option) => {
          defaultSelection[option.name] = option.value;
        });
        setPrice(matched.variant.price);
        setSelectedVariant({
          ...matched,
        });
      } else {
        product?.variants?.[0]?.selected_options?.forEach((option) => {
          defaultSelection[option.name] = option.value;
        });
      }
      return defaultSelection;
    });

    useEffect(() => {
      if (!calculating) {
        setOldQ(selectedVariant.quantity);
      }
      // eslint-disable-next-line
    }, [calculating]);

    useEffect(() => {
      const initialQuantity = cart?.lineItems?.find(
        ({ variant }) =>
          variant?.variant_id === selectedVariant?.variant?.variant_id
      );

      setSelectedVariant({
        ...selectedVariant,
        quantity: initialQuantity ? initialQuantity.quantity : 0,
      });
      // eslint-disable-next-line
    }, [cart.lineItems]);

    const handleOptionChange = (optionName: string, optionValue: string) => {
      const selectedOptions: any = {
        ...selectedOption,
        [optionName]: optionValue,
      };

      let getselectedVariant = product.variants.filter((variant) => {
        let selected = false;
        for (let i = 0; i < variant.selected_options.length; i++) {
          const option = variant.selected_options[i];
          selected =
            selectedOptions[option.name] &&
            selectedOptions[option.name] === option.value;
          if (!selected) {
            break;
          }
        }
        return selected;
      })[0];

      setPrice(getselectedVariant?.price);

      const findProduct = cart?.lineItems?.find(
        ({ variant }) => variant?.variant_id === getselectedVariant?.variant_id
      );

      const selected = {
        variant: { ...getselectedVariant, main_product_id: product.product_id },
        quantity: findProduct ? findProduct.quantity : 0,
      };

      setSelectedVariant(selected);
      setSelectedOption(selectedOptions);
      updateCart(selected);
    };

    const handleQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
      if (
        selectedVariant &&
        Object.keys(selectedVariant).length > 0 &&
        selectedVariant?.variant?.price
      ) {
        const currentProductVariants = {
          ...selectedVariant,
          quantity: Number(e.target.value),
        };
        currentProductVariants.variant.main_product_id = product.product_id;
        // changeCartQuantity(
        //   currentProductVariants,
        //   shopifyCartConfig,
        //   setShopifyCartConfig
        // );
        setSelectedVariant(currentProductVariants);
        updateCart(currentProductVariants);
      }
    };

    useEffect(() => {
      const getCardStyle = () => {
        if (
          selectedVariant &&
          selectedVariant?.quantity &&
          selectedVariant?.quantity > 0
        ) {
          return { border: `1px solid ${color}` };
        }
        return {};
      };

      setCardStyle(getCardStyle());
    }, [selectedVariant, color]);

    const filteredByTitle = product.options.filter(
      ({ name }) => name !== "Title"
    );

    const discountedPrice = (totalDiscount: number) => {
      return selectedVariant.quantity > 0
        ? (totalDiscount / oldQ).toFixed(2)
        : totalDiscount;
    };

    const getQuantityOptionView = (isRightBuyLater = false) => (
      <div className="flex gap-3 justify-between">
        <div className="flex items-start gap-3">
          {filteredByTitle.length > 0 && (
            <div className="flex">
              {filteredByTitle.map((option, index) => {
                const dupesRemoved = Array.from(new Set(option.values));

                return (
                  <label
                    className={`select-label ${
                      filteredByTitle.length > 1 && "mr-2"
                    }`}
                    key={index}
                  >
                    <select
                      onChange={(e) =>
                        handleOptionChange(option.name, e.target.value)
                      }
                      key={option.name}
                      name={option.name}
                      id={option.name}
                      value={selectedOption[option.name]}
                      className="py-2 xl:pr-8 pr-5 pl-2  block my-1 bg-white md:rounded-md md:py-3 border border-designerGray md:border-opacity-50 text-11 max-w-80px"
                    >
                      {dupesRemoved.map((optionValue, index) => (
                        <option key={index} value={optionValue}>
                          {optionValue}
                        </option>
                      ))}
                    </select>
                  </label>
                );
              })}
            </div>
          )}

          <label className="select-label">
            <select
              onChange={(e) => handleQuantityChange(e)}
              value={selectedVariant && selectedVariant.quantity}
              className=" border border-designerGray border-opacity-50 block my-1 bg-white md:rounded-md md:py-3 pr-8 pl-2 py-2 text-xs"
              name="quantity"
              id="quantity"
            >
              {qtyPickerOptions}
            </select>
          </label>
        </div>
      </div>
    );

    const topProduct = (
      <div
        className={`flex flex-col justify-around bg-white rounded-lg border-0 relative ${
          main ? "shadow" : ""
        }`}
        style={cardStyle}
      >
        <div className="flex mr-2 justify-between p-4 md:p-0 w-full">
          <div className="img-wrap relative w-12 lg:w-60 md:w-40 flex items-center justify-center rounded-md xl:max-h-48 lg:max-h-40 md:max-h-24 p-1">
            <img
              className="md:w-full h-full w-12 rounded-md object-contain"
              src={product.image_url}
              alt={product.title}
            />
          </div>

          <div className="content-wrap w-full relative md:p-6">
            <div className="flex justify-between md:pl-0 pl-3">
              <h1 className="font-semibold font-mediumAvenir text-base tracking-wide text-designerGray">
                {product.title}
              </h1>
              {price && (
                <div className="text-right">
                  {discounts && +discounts[product.product_id] > 0 ? (
                    <>
                      <p className="font-mediumAvenir font-medium text-blue-500">
                        $
                        {discountedPrice(
                          parseFloat(discounts[product.product_id])
                        )}
                      </p>
                      <span className="text-sm relative">
                        <span className="line-through absolute"></span>${price}
                      </span>
                    </>
                  ) : (
                    <p className="font-mediumAvenir font-medium">${price}</p>
                  )}
                </div>
              )}
            </div>
            <div className="product-content lg:my-4 lg:mb-6 md:block hidden xl:min-h-35px lg:min-h-fit">
              <p className="text-designerGray text-xs tracking-wide font-normal">
                {product.description}
              </p>
            </div>

            <div className="flex-col flex-grow justify-end lg:block hidden bottom-7 left-8 lg:mt-9 xl:mt-0">
              {getQuantityOptionView()}
            </div>
          </div>
        </div>

        <div className="flex-col flex-grow justify-end flex lg:hidden lg:p-6 p-4">
          {getQuantityOptionView()}
        </div>
      </div>
    );

    const otherProduct = (
      <div
        className={`flex flex-col justify-around bg-white rounded-lg border-0 relative ${
          main ? "shadow" : ""
        }`}
        style={cardStyle}
      >
        <div className="flex mr-2 p-4 pb-3 w-full">
          <div className="img-wrap relative w-12 h-12 flex items-center justify-center">
            <img
              className="w-12 h-12 rounded-md bg-gray-100"
              src={product.image_url}
              alt={product.title}
            />
          </div>

          <div className="content-wrap w-full pl-3">
            <div className="flex justify-between">
              <h1 className="font-semibold font-mediumAvenir text-sm tracking-wide text-designerGray">
                {product.title.length > 30
                  ? product.title.substring(0, 30) + "..."
                  : product.title}
              </h1>
              <div className="text-right">
                {discounts && +discounts[product.product_id] > 0 ? (
                  <>
                    <p className="font-mediumAvenir font-medium text-blue-500">
                      $
                      {discountedPrice(
                        parseFloat(discounts[product.product_id])
                      )}
                    </p>
                    <span className="text-sm relative">
                      <span className="line-through absolute"></span>${price}
                    </span>
                  </>
                ) : (
                  <p className="font-mediumAvenir font-medium">${price}</p>
                )}
              </div>
              {/* {price && (
                <p className="font-medium font-mediumAvenir">${price}</p>
              )} */}
            </div>
          </div>
        </div>

        <div className="flex-col flex-grow justify-end p-4 pb-2 pt-0">
          {getQuantityOptionView(true)}
        </div>
      </div>
    );

    return (
      <>
        {isTop ? topProduct : otherProduct}
        <ProductSavePopUp alert={alert} setAlert={setAlert} />
      </>
    );
  }
);
