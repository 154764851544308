import { ChangeEvent, useEffect, useState } from "react";
import { Product, ProductOption } from "../../../models/ConfigurationModel";

const BrandProductReminder = ({
  product,
  color,
  purchaseUrl,
}: {
  product: Product;
  color: string;
  purchaseUrl: string;
}) => {
  const [price, setPrice] = useState<string>(product?.variants?.[0]?.price);

  const [selectedOption, setSelectedOption] = useState(() => {
    const defaultSelection: any = {};
    product?.variants?.[0]?.selected_options?.forEach((option) => {
      defaultSelection[option.name] = option.value;
    });
    return defaultSelection;
  });

  const originalOptions = product.options
    .filter(({ name }) => name !== "Title")
    .map((item) => ({ ...item, values: Array.from(new Set(item.values)) }));
  const [filteredByTitle, setFilteredByTitle] = useState<ProductOption[]>([
    ...originalOptions,
  ]);

  const [cardStyle, setCardStyle] = useState({});

  const [selectedVariant, setSelectedVariant] = useState<any>(() => {
    return {
      variant: product.variants[0],
      quantity: 0,
    };
  });

  const qtyPickerOptions: any[] = [];

  const getVariantions = (value: number) => {
    if (value === 1) {
      return "select-1";
    } else if (value === 2) {
      return "select-2";
    } else {
      return "select-3";
    }
  };

  for (let i: number = 0; i < 20; i++) {
    qtyPickerOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const handleOptionChange = (optionName: string, optionValue: string) => {
    const selectedOptions: any = {
      ...selectedOption,
      [optionName]: optionValue,
    };

    const newOptions: ProductOption[] = originalOptions.map((item) => {
      if (item.name !== optionName) {
        return { name: item.name, values: [] };
      } else {
        return { ...item };
      }
    });
    product.variants.forEach((variant) => {
      if (
        variant.selected_options.some(
          (item) => item.name === optionName && item.value === optionValue
        )
      ) {
        variant.selected_options.forEach((opItem) => {
          if (opItem.name !== optionName) {
            const existingOption = newOptions.findIndex(
              (nItem) => nItem.name === opItem.name
            );
            if (existingOption === -1) {
              newOptions.push({ name: opItem.name, values: [opItem.value] });
            } else {
              newOptions[existingOption].values.push(opItem.value);
            }
          }
        });
      }
    });
    setFilteredByTitle(newOptions);

    const possibleVariants = product.variants.filter((variant) =>
      variant.selected_options.some(
        (item) => item.name === optionName && item.value === optionValue
      )
    );
    const oldSelected: any = { ...selectedOption, [optionName]: optionValue };
    let getselectedVariant: any = null;
    const selectedOptionsVariant = possibleVariants?.find((variant) => {
      let selected = false;
      for (let i = 0; i < variant.selected_options.length; i++) {
        const option = variant.selected_options[i];
        selected =
          oldSelected[option.name] && oldSelected[option.name] === option.value;
        if (!selected) {
          break;
        }
      }
      return selected;
    });
    if (!selectedOptionsVariant) {
      getselectedVariant = possibleVariants?.find((variant) =>
        variant.selected_options.some(
          (item) => item.name === optionName && item.value === optionValue
        )
      );
    } else {
      getselectedVariant = { ...selectedOptionsVariant };
    }
    setSelectedOption(selectedOptions);
    setPrice(getselectedVariant?.price);

    const selected = {
      variant: { ...getselectedVariant, main_product_id: product.product_id },
      quantity: 0,
    };

    setSelectedVariant(selected);
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (
      selectedVariant &&
      Object.keys(selectedVariant).length > 0 &&
      selectedVariant?.variant?.price
    ) {
      const currentProductVariants = {
        ...selectedVariant,
        quantity: Number(e.target.value),
      };
      currentProductVariants.variant.main_product_id = product.product_id;

      setSelectedVariant(currentProductVariants);
    }
  };

  useEffect(() => {
    const getCardStyle = () => {
      if (
        selectedVariant &&
        selectedVariant?.quantity &&
        selectedVariant?.quantity > 0
      ) {
        return { border: `1px solid ${color}` };
      }
      return {};
    };

    setCardStyle(getCardStyle());
  }, [selectedVariant, color]);

  const getQuantityOptionView = (isRightBuyLater = false) => (
    <div className="flex gap-3 justify-between">
      <div className="flex items-start gap-3 dropBoxWrapper">
        {filteredByTitle.length > 0 && (
          <div className="flex">
            {filteredByTitle.map((option, index) => {
              const dupesRemoved = Array.from(new Set(option.values));

              return (
                <label
                  className={`select-label ${getVariantions(
                    filteredByTitle?.length
                  )} ${filteredByTitle.length > 1 && "mr-2"}`}
                  key={index}
                >
                  <select
                    onChange={(e) =>
                      handleOptionChange(option.name, e.target.value)
                    }
                    key={option.name}
                    name={option.name}
                    id={option.name}
                    value={selectedOption[option.name]}
                    // className="py-2 xl:pr-8 pr-5 pl-2  block my-1 bg-white md:rounded-md md:py-3 border border-designerGray md:border-opacity-50 text-11 max-w-80px"
                    // className="py-2 xl:pr-8 pr-5 pl-2  block my-1 bg-white md:rounded-md md:py-3 border border-designerGray md:border-opacity-50 text-11 max-w-80px"
                    className="py-2 xl:pr-8 pr-5 pl-2  block my-1 bg-white md:rounded-md md:py-3 border border-designerGray border-opacity-50 text-11 max-w-80px"
                  >
                    {dupesRemoved.map((optionValue, index) => (
                      <option key={index} value={optionValue}>
                        {optionValue}
                      </option>
                    ))}
                  </select>
                </label>
              );
            })}
          </div>
        )}

        <label className="select-label">
          <select
            onChange={(e) => handleQuantityChange(e)}
            value={selectedVariant && selectedVariant.quantity}
            className=" border border-designerGray border-opacity-50 block my-1 bg-white md:rounded-md md:py-3 pr-8 pl-2 py-2 text-xs"
            name="quantity"
            id="quantity"
          >
            {qtyPickerOptions}
          </select>
        </label>
      </div>
    </div>
  );

  return (
    <div
      className="bg-white p-4 mb-3 product-block productCsBox rounded-md"
      style={cardStyle}
    >
      <div className="flex mb-4 items-start">
        <div className="w-12 h-12 min-w-12">
          <img
            className="rounded-lg w-full h-full"
            src={product.image_url}
            alt={product.title}
          ></img>
        </div>
        <div className="w-8/12 pl-4 flex flex-col justify-center">
          <h4 className="font-semibold font-mediumAvenir md:text-sm text-base mb-2 one-line text-designerGray tracking-wide">
            {product.title}
          </h4>
          <span className="text-designerGray text-opacity-75 tracking-wide text-xs text-variant">
            {selectedVariant.variant.variant_name === "Default Title" ? (
              <br />
            ) : (
              selectedVariant.variant.variant_name
            )}
          </span>
        </div>
        {price && (
          <div className="text-right ml-auto pl-1">
            {selectedVariant?.variant?.discounted_price > 0 ? (
              <>
                <p className="font-mediumAvenir font-medium text-darkAssistBlue">
                  ${selectedVariant?.variant?.discounted_price.toFixed(2)}
                </p>
                <span className="text-sm relative">
                  <span className="line-through absolute"></span>${price}
                </span>
              </>
            ) : (
              <p className="font-mediumAvenir font-medium AvenirMediumtext">
                ${price}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between flex-wrap items-center">
        <div className="dropdown-wrap w-4/6 pr-3">
          {getQuantityOptionView()}
        </div>
        <div className="md:w-[92px] md:h-[42px] w-[86px] h-[34px] text-right inline-flex">
          <button
            className={`btn text-xs ${
              selectedVariant.quantity === 0 &&
              "cursor-not-allowed disabled:opacity-50 bg-blue-500 border-none"
            }`}
            disabled={selectedVariant.quantity === 0 ? true : false}
            onClick={() => {
              if (selectedVariant.variant) {
                const variantIdArr =
                  selectedVariant.variant.variant_id.split("/");
                const variantId = variantIdArr[variantIdArr.length - 1];
                window.open(
                  `https://${purchaseUrl}/cart/${variantId}:${selectedVariant.quantity}`,
                  "_blank"
                );
              }
            }}
          >
            {/* <a href="#!" target="_blank" rel="noopener noreferrer"> */}
            Purchase
            {/* </a> */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandProductReminder;
