import { ReactChild, useContext, useEffect, useState } from "react";
import { Address } from "../../checkout/model/CheckoutModel";
import { CustomerUserAddress } from "../../../models/CustomerUserModel";
import { AddressFields } from "../../../components/AddressFields";
import { ShipTo, saveAddress } from "./CartDetails";
import { FormLoader } from "../../../components/Loading";
import { updateAddress } from "../../../api/customerUserApi";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomerUserContext } from "../../../contexts/CustomerUserContext";

const DrawerWrapper = ({
  name,
  children,
  setCurrentEditItem,
  currentEditItem,
  onClose,
}: {
  name: string;
  children: ReactChild;
  currentEditItem: string;
  setCurrentEditItem: (newItem: string) => void;
  onClose?: () => void;
}) => {
  return (
    <div
      className={`drawer p-5 fixed bottom-0 bg-white w-full left-0 h-min shadow-drawer_shadow transition-all ease-in-out duration-500  ${
        currentEditItem === name
          ? " transform translate-y-0"
          : " transform translate-y-full"
      }`}
    >
      {/* <div className="overlay fixed bg-white bg-opacity-10 top-0 left-0 w-full h-full"></div> */}
      <span
        className="absolute left-6 top-6"
        onClick={() => {
          setCurrentEditItem("");
          if (onClose) {
            onClose();
          }
        }}
      >
        <img src="\back-arrow.svg" alt=""></img>
      </span>
      {children}
    </div>
  );
};

export const CartPriceDrawers = ({
  currentEditItem,
  setCurrentEditItem,
  refresh,
}: {
  currentEditItem: string;
  setCurrentEditItem: (newItem: string) => void;
  refresh: () => void;
}) => {
  const { customerUser, loading } = useContext(CustomerUserContext);

  const { getAccessTokenSilently } = useAuth0();

  const initialAddress = {
    address1: "",
    address2: "",
    city: "",
    country_code: "",
    province_code: "",
    zip: "",
  };
  const [newAddress, setNewAddress] = useState<Address>({ ...initialAddress });

  const [fullname, setFullname] = useState<string>();

  const [showNewAddress, setShowNewAddress] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<CustomerUserAddress[]>([]);
  const [defaultId, setDefaultId] = useState<number>();
  const [isAddressSaveButtonDisabled, setIsAddressSaveButtonDisabled] =
    useState<boolean>(false);

  const [errors, setErrors] = useState<any>();

  const onSaveAddress = async () => {
    const accessToken = await getAccessTokenSilently();

    if (customerUser && fullname) {
      await saveAddress({
        customerUser,
        newAddress,
        setErrors,
        updateCustomerUser: refresh,
        setAddresses,
        setDefaultId,
        setShowNewAddress,
        setIsAddressSaveButtonDisabled,
        accessToken,
        fullname,
      });
    }
    setNewAddress({ ...initialAddress });
    refresh();
  };

  const setDefaultAddress = (id: number, index: number) => {
    if (!customerUser) return;

    getAccessTokenSilently()
      .then((accessToken) =>
        updateAddress(customerUser.phone, { id, default: true }, accessToken)
      )
      .then(() => {
        const updated = [...addresses];
        updated.splice(index, 1, { ...updated[index], default: true });
        setAddresses(updated);
        refresh();
      });
  };

  useEffect(() => {
    if (customerUser && !loading) {
      setAddresses(customerUser.shipping_addresses);
      setDefaultId(customerUser.default_shipping_address?.id);
    }
  }, [customerUser, loading]);

  return (
    <>
      <div
        className={`test-dr p-5 fixed bottom-0 bg-white w-full left-0 h-min shadow-drawer_shadow transition-all ease-in-out duration-500 z-20  ${
          showNewAddress
            ? " transform translate-y-0"
            : " transform translate-y-full"
        }`}
      >
        <span
          className="absolute left-6 top-6"
          onClick={() => {
            setShowNewAddress(false);
            setNewAddress({ ...initialAddress });
          }}
        >
          <img src="\back-arrow.svg" alt=""></img>
        </span>
        <div className={`text-center transition-all ease-in-out duration-500`}>
          {" "}
          <div className="text-center">
            <span className="text-designerGray text-base font-semibold font-mediumAvenir tracking-wide">
              Add New Address
            </span>
          </div>
          {isAddressSaveButtonDisabled ? (
            <div className="absolute right-5 top-5 text-blue-600 text-sm">
              <FormLoader />
            </div>
          ) : (
            <div className="absolute right-5 top-6 text-blue-600 text-sm">
              <button
                disabled={isAddressSaveButtonDisabled}
                onClick={() => onSaveAddress()}
              >
                Save
              </button>
            </div>
          )}
          <div className="mt-8">
            {showNewAddress && (
              <AddressFields
                onAddressChange={(address: Address) =>
                  setNewAddress({ ...newAddress, ...address })
                }
                edit={false}
                initial={newAddress}
                errors={errors}
                showName={true}
                onNameChange={setFullname}
              />
            )}
          </div>
        </div>
      </div>
      <DrawerWrapper
        name="ship_to"
        setCurrentEditItem={setCurrentEditItem}
        currentEditItem={currentEditItem}
        onClose={() => setShowNewAddress(false)}
      >
        <div className={`text-center transition-all ease-in-out duration-500`}>
          <span className="text-designerGray text-base font-semibold font-mediumAvenir tracking-wide">
            Ship to
          </span>
          <div className="mt-8">
            <div className="single-address overflow-scroll flex flex-col text-left pb-6border-black border-opacity-10 mb-5">
              <ShipTo
                addresses={addresses}
                customerUser={customerUser || null}
                defaultId={defaultId}
                setDefaultId={setDefaultAddress}
                viewFrom="mobile"
              />
            </div>
            <div
              className="add-new flex justify-between"
              onClick={() => setShowNewAddress(true)}
            >
              <h3 className="text-xs text-designerGray tracking-wide font-medium">
                Add a new shipping addresss
              </h3>
              <img src="\right-angle.svg" alt=""></img>
            </div>
          </div>
        </div>
      </DrawerWrapper>
    </>
  );
};
